import React from "react";
import { useTranslation } from "react-i18next";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { Avatar } from "@mui/material";
import { user } from "../../../../../assets/constants/global";
import { AiOutlineCamera } from "react-icons/ai";

export default function ProfileImage() {
  const dispatch = useDispatch();
  const { editEmployeeForm, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <div className="image-main-div">
          <div className="image-inner-div">
            <Avatar
              src={editEmployeeForm?.profileImageNew !== undefined ?
                URL.createObjectURL(editEmployeeForm?.profileImageNew) :
                editEmployeeForm?.profileImage
                  ? editEmployeeForm?.profileImage
                  : user
              }
              alt={t("employees.profileImage")}
              style={{ width: 100, height: 100 }}
            />
            <label style={{ cursor: "pointer" }}
              htmlFor={editEmployeeForm?.profileImage !== undefined ? "profileImage" : "profileImageNew"}>
              <div style={{ position: "relative" }}>
                <span>
                  <AiOutlineCamera size={28} className="image-icon" />
                </span>
              </div>

              <input
                id={editEmployeeForm?.profileImage !== undefined ? "profileImage" : "profileImageNew"}
                name={editEmployeeForm?.profileImage !== undefined ? "profileImage" : "profileImageNew"}
                autoComplete={editEmployeeForm?.profileImage !== undefined ? "profileImage" : "profileImageNew"}
                autoFocus
                type="file"
                hidden
                size="small"
                placeholder={t("employees.profileImage")}
                onChange={(e) =>
                  handleEditEmployeeInputChange(
                    e,
                    editEmployeeForm,
                    dispatch, t, employees
                  )
                }
              />
              <br />
            </label>
          </div>
        </div>
        <h3>{t("employees.profileImage")}</h3>
      </div>
    </>
  );
}
