import React, { useEffect } from "react";
import CarouselComponent from "../../../components/global/carousel/CarouselComponent";
import Footer from "../../../components/global/footer/Footer";
import Header from "../../../components/global/header/Header";
import WhatsAppButton from "../../../components/global/whatsappButton/WhatsappButton";
import ContactusForm from "../../../components/global/contactusForm/ContactusForm";
import AboutUs from "../../../components/global/aboutUs/AboutUs";
import OurServices from "../../../components/global/ourServices/OurServices";
import "../../../assets/styles/Services.css"
import { useTranslation } from "react-i18next";
import { scrollToTop } from "../../../utils/scrollToTop";

export default function LandingPage() {
  const { t } = useTranslation()
  useEffect(() => {
    scrollToTop();   // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header />
      <CarouselComponent />
      <div className="container">
        <h1 style={{ textAlign: "center" }}>{t("global.landingPage")}</h1>
        <p style={{ textAlign: "center" }}>{t("global.landingDescription")}</p>
      </div>
      <AboutUs />
      <OurServices />
      <ContactusForm />
      <Footer />
      <WhatsAppButton />
    </>
  );
}
