import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAndCompareUserById, fetchData, } from "./libs/employees/employeesFunctions";
import { Navigate, Route, Routes } from "react-router-dom";
import { fetchLeaveData } from "./libs/leaves/getLeaves/getLeavesFunctions";
import { fetchRequestData } from "./libs/requests/getRequests/getRequestFunctions";
import { setWidth } from "./redux/reduxSlice/globalSlice";
// global
import ErrorScreen from "./pages/global/errorScreen/ErrorScreen";
import LandingPage from "./pages/global/landingPage/LandingPage";
import About from "./pages/global/about/About";
import Services from "./pages/global/services/Services";
import Contact from "./pages/global/contact/Contact";
import Login from "./pages/global/login/Login";
import PrivateRoute from "./routes/PrivateRoute";
import AppLayout from "./layout/Layout";
import Employees from "./pages/global/employees/Employees";
import AddNewEmployees from "./pages/global/employees/AddNewEmployees";
import ViewEmployees from "./pages/global/employees/ViewEmployees";
import EditEmployee from "./pages/global/employees/EditEmployee";
// user
import Dashboard from "./pages/user/dashboard/Dashboard";
import Profile from "./pages/user/profile/Profile";
import SickLeave from "./pages/user/sickLeave/SickLeave";
import ViewSickLeave from "./pages/user/sickLeave/ViewSickLeave";
import RequestNewSickLeave from "./pages/user/sickLeave/RequestNewSickLeave";
import AnnualLeave from "./pages/user/annualLeave/AnnualLeave";
import ViewAnnualLeave from "./pages/user/annualLeave/ViewAnnualLeave";
import RequestNewAnnualLeave from "./pages/user/annualLeave/RequestNewAnnualLeave";
import Requests from "./pages/user/requests/Requests";
import AddNewRequest from "./pages/user/requests/AddNewRequest";
import ViewRequest from "./pages/user/requests/ViewRequest";
// hr
import HrDashboard from "./pages/hr/hrDashboard/HrDashboard";
import SickLeaveHr from "./pages/hr/sickLeaveHr/SickLeaveHr";
import ViewSickLeaveHr from "./pages/hr/sickLeaveHr/ViewSickLeaveHr";
import RequestNewSickLeaveHr from "./pages/hr/sickLeaveHr/RequestNewSickLeaveHr";
import AnnualLeaveHr from "./pages/hr/annualLeaveHr/AnnualLeaveHr";
import ViewAnnualLeaveHr from "./pages/hr/annualLeaveHr/ViewAnnualLeaveHr";
import RequestNewAnnualLeaveHr from "./pages/hr/annualLeaveHr/RequestNewAnnualLeaveHr";
import RequestsHr from "./pages/hr/requestsHr/RequestsHr";
import ViewRequestHr from "./pages/hr/requestsHr/ViewRequestHr";
import AddNewRequestHr from "./pages/hr/requestsHr/AddNewRequestHr";
import HrProfile from "./pages/hr/hrProfile/HrProfile";
import EditHrProfile from "./pages/hr/hrProfile/EditHrProfile";
// supervisor
import SupervisorDashboard from "./pages/supervisor/supervisorDashboard/SupervisorDashboard";
import SickLeaveSupervisor from "./pages/supervisor/sickLeaveSupervisor/SickLeaveSupervisor";
import ViewSickLeaveSupervisor from "./pages/supervisor/sickLeaveSupervisor/ViewSickLeaveSupervisor";
import RequestNewSickLeaveSupervisor from "./pages/supervisor/sickLeaveSupervisor/RequestNewSickLeaveSupervisor";
import AnnualLeaveSupervisor from "./pages/supervisor/annualLeaveSupervisor/AnnualLeaveSupervisor";
import ViewAnnualLeaveSupervisor from "./pages/supervisor/annualLeaveSupervisor/ViewAnnualLeaveSupervisor";
import RequestNewAnnualLeaveSupervisor from "./pages/supervisor/annualLeaveSupervisor/RequestNewAnnualLeaveSupervisor";
import RequestsSupervisor from "./pages/supervisor/requestsSupervisor/RequestsSupervisor";
import ViewRequestSupervisor from "./pages/supervisor/requestsSupervisor/ViewRequestSupervisor";
import AddNewRequestSupervisor from "./pages/supervisor/requestsSupervisor/AddNewRequestSupervisor";
import SupervisorProfile from "./pages/supervisor/supervisorProfile/SupervisorProfile";
import EditSupervisorProfile from "./pages/supervisor/supervisorProfile/EditSupervisorProfile";
import TermsOfUse from "./pages/global/termsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/global/privacyPolicy/PrivacyPolicy";
import MonthlySickLeaveHr from "./pages/hr/sickLeaveHr/MonthlySickLeaveHr";
import MonthlyAnnualLeaveHr from "./pages/hr/annualLeaveHr/MonthlyAnnualLeaveHr";
import MonthlyRequestsHr from "./pages/hr/requestsHr/MonthlyRequestsHr";
import MonthlySickLeaveSupervisor from "./pages/supervisor/sickLeaveSupervisor/MonthlySickLeaveSupervisor";
import MonthlyAnnualLeaveSupervisor from "./pages/supervisor/annualLeaveSupervisor/MonthlyAnnualLeaveSupervisor";
import MonthlyRequestSupervisor from "./pages/supervisor/requestsSupervisor/MonthlyRequestSupervisor";
import AnnualLeaveByMonthHr from "./pages/hr/annualLeaveHr/AnnualLeaveByMonthHr";
import SickLeaveByMonthHr from "./pages/hr/sickLeaveHr/SickLeaveByMonthHr";
import RequestsByMonthHr from "./pages/hr/requestsHr/RequestsByMonthHr";
import SickLeaveByMonthSupervisor from "./pages/supervisor/sickLeaveSupervisor/SickLeaveByMonthSupervisor";
import AnnualLeaveByMonthSupervisor from "./pages/supervisor/annualLeaveSupervisor/AnnualLeaveByMonthSupervisor";
import RequestByMonthSupervisor from "./pages/supervisor/requestsSupervisor/RequestByMonthSupervisor";


function App() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData(dispatch);
    fetchLeaveData(dispatch);
    fetchRequestData(dispatch);
    fetchAndCompareUserById(user);
    const handleResize = () => dispatch(setWidth(window.innerWidth));
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup the resize listener
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Include dependencies

  return (
    <>
      <Routes>
        {/* global */}
        <Route path={"*"} element={<ErrorScreen />} />
        <Route path={"/"} element={
          user?.role === undefined ?
            <LandingPage /> : user?.role === "user" ?
              <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                <Navigate to="/eitmamland/supervisor" /> :
                <Navigate to="/eitmamland/hr" />
        } />
        <Route path={"/about"} element={<About />} />
        <Route path={"/services"} element={<Services />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/privacyPolicy"} element={<PrivacyPolicy />} />
        <Route path={"/termsOfUse"} element={<TermsOfUse />} />
        <Route path={"/login"} element={
          user?.role === undefined ?
            <Login /> : user?.role === "user" ?
              <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                <Navigate to="/eitmamland/supervisor" /> :
                <Navigate to="/eitmamland/hr" />
        } />
        {/* user */}
        <Route path={"/eitmamland"}>
          <Route path={"user"} element={
            user?.role === "user" ?
              <PrivateRoute>
                <AppLayout>
                  <Dashboard />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/profile"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <Profile />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/sickLeave"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <SickLeave />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/viewSickLeave"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewSickLeave />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/sickLeave/new"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestNewSickLeave />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/annualLeave"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <AnnualLeave />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/viewAnnualLeave"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewAnnualLeave />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/annualLeave/new"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestNewAnnualLeave />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/requests"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <Requests />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/requests/new"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <AddNewRequest />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"user/viewRequests"} element={
            user?.role === "user" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewRequest />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "hr" ?
                <Navigate to="/eitmamland/hr" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          {/* hr */}
          <Route path={"hr"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <HrDashboard />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/employees"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <Employees />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/newEmployee"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <AddNewEmployees />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/viewEmployee"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewEmployees />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/editEmployee"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <EditEmployee />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/sickLeave"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <SickLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/monthlySickLeave"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <MonthlySickLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/monthlySickLeave/:month"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <SickLeaveByMonthHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/viewSickLeave"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewSickLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/sickLeave/new"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestNewSickLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/annualLeave"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <AnnualLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/monthlyAnnualLeave"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <MonthlyAnnualLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/monthlyAnnualLeave/:month"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <AnnualLeaveByMonthHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/viewAnnualLeave"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewAnnualLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/annualLeave/new"} element={
            user?.role === "hr" ?
              <PrivateRoute>
                <AppLayout>
                  <RequestNewAnnualLeaveHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/requests"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestsHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/monthlyRequests"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <MonthlyRequestsHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/monthlyRequests/:month"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestsByMonthHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/viewRequests"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewRequestHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/requests/new"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <AddNewRequestHr />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/profile"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <HrProfile />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />
          <Route path={"hr/editProfile"} element={
            user?.role === "hr" ?
              <PrivateRoute >
                <AppLayout>
                  <EditHrProfile />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "supervisor" ?
                  <Navigate to="/eitmamland/supervisor" /> :
                  <Navigate to="/" />
          } />


          {/* supervisor */}
          <Route path={"supervisor"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <SupervisorDashboard />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/employees"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <Employees />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/newEmployee"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <AddNewEmployees />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/viewEmployee"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewEmployees />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/editEmployee"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <EditEmployee />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/sickLeave"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <SickLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/monthlySickLeave"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <MonthlySickLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/monthlySickLeave/:month"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <SickLeaveByMonthSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/viewSickLeave"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewSickLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/sickLeave/new"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestNewSickLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/annualLeave"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <AnnualLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/monthlyAnnualLeave"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <MonthlyAnnualLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/monthlyAnnualLeave/:month"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <AnnualLeaveByMonthSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/viewAnnualLeave"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewAnnualLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/annualLeave/new"} element={
            user?.role === "supervisor" ?
              <PrivateRoute>
                <AppLayout>
                  <RequestNewAnnualLeaveSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/requests"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestsSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/monthlyRequests"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <MonthlyRequestSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/monthlyRequests/:month"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <RequestByMonthSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/viewRequests"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <ViewRequestSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/requests/new"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <AddNewRequestSupervisor />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/profile"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <SupervisorProfile />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
          <Route path={"supervisor/editProfile"} element={
            user?.role === "supervisor" ?
              <PrivateRoute >
                <AppLayout>
                  <EditSupervisorProfile />
                </AppLayout>
              </PrivateRoute> :
              user?.role === "user" ?
                <Navigate to="/eitmamland/user" /> : user?.role === "hr" ?
                  <Navigate to="/eitmamland/hr" /> :
                  <Navigate to="/" />
          } />
        </Route>
      </Routes >
    </>
  );
}

export default App;
