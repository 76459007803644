import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Avatar, TextField } from "@mui/material";
import { logo } from "../../../../../assets/constants/global";
import { handleOpenImage } from "../../../../../utils/openImage";

export default function VisaNumberAndImage() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={state?.visaImage === undefined ? logo : state?.visaImage}
            alt={t("employees.visaImage")}
            style={{ width: 100, height: 100 }}
            onClick={() => handleOpenImage(state?.visaImage === undefined ? logo : state?.visaImage)}
          />
          <h3>{t("employees.visaImage")}</h3>
        </div>
        <TextField
          fullWidth
          value={state?.visaNumber}
          size="small"
          type="text"
          label={t("employees.visaNumber")}
          placeholder="e.g.  1387182719827"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
