import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../assets/i18n/i18n";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/Sidebar.css";
import { useSelector } from "react-redux";
import { FaHome, FaUser, FaUsers } from "react-icons/fa";
import { FaCodePullRequest } from "react-icons/fa6";
import { MdSick, MdFlight } from "react-icons/md";

export default function Sidebar() {
  const { user } = useSelector((state) => state.user);
  const { width } = useSelector((state) => state.global);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sidebar = [
    {
      id: 1,
      title:
        user?.role === "user"
          ? t("sidebar.dashboard")
          : t("sidebarHr.dashboard"),
      icon: <FaHome />,
      link: user?.role === "user" ? "/eitmamland/user" : user?.role === "hr" ? "/eitmamland/hr" : "/eitmamland/supervisor",
    },
    {
      id: 2,
      title:
        user?.role === "user"
          ? t("sidebar.sickLeave")
          : t("sidebarHr.employees"),
      icon: user?.role === "user" ? <MdSick /> : <FaUsers />,
      link:
        user?.role === "user"
          ? "/eitmamland/user/sickLeave"
          : user?.role === "hr" ? "/eitmamland/hr/employees" : "/eitmamland/supervisor/employees",
    },
    {
      id: 3,
      title:
        user?.role === "user"
          ? t("sidebar.annualLeave")
          : t("sidebarHr.sickLeave"),
      icon: user?.role === "user" ? <MdFlight /> : <MdSick />,
      link:
        user?.role === "user"
          ? "/eitmamland/user/annualLeave"
          : user?.role === "hr" ? "/eitmamland/hr/sickLeave" : "/eitmamland/supervisor/sickLeave",

    },
    {
      id: 4,
      title: user?.role === "user" ? t("sidebar.request") : t("sidebarHr.annualLeave"),
      icon: user?.role === "user" ? <FaCodePullRequest /> : <MdFlight />,
      link: user?.role === "user" ? "/eitmamland/user/requests" : user?.role === "hr" ? "/eitmamland/hr/annualLeave" : "/eitmamland/supervisor/annualLeave",

    },
    {
      id: 5,
      title:
        user?.role === "user" ? t("sidebar.profile") : t("sidebarHr.request"),
      icon: user?.role === "user" ? <FaUser /> : <FaCodePullRequest />,
      link:
        user?.role === "user"
          ? "/eitmamland/user/profile"
          : user?.role === "hr" ? "/eitmamland/hr/requests" : "/eitmamland/supervisor/requests",

    },
    {
      id: 6,
      title:
        user?.role === "user" ? "" : t("sidebarHr.profile"),
      icon: user?.role === "user" ? "" : <FaUser />,
      link:
        user?.role === "user"
          ? ""
          : user?.role === "hr" ? "/eitmamland/hr/profile" : "/eitmamland/supervisor/profile",

    },
  ];

  return (
    <>
      <div
        className="sidebar-outer"
        style={{
          display: width > 900 ? "flex" : "none",
          left: i18n.language === "en" ? 0 : "auto",
          right: i18n.language !== "en" ? 0 : "auto",
          top: 60,
        }}
      >
        <div className="sidebar-main">
          <div className="sidebar-main-inner">
            {sidebar?.map((item) => {
              return (
                <>
                  <span
                    key={item?.id}
                    style={{
                      justifyContent:
                        i18n.language === "en" ? "flex-start" : "flex-end",
                    }}
                    className="sidebar-content-item"
                    onClick={() => navigate(item?.link)}
                  >
                    {i18n.language === "en" ? (
                      <>
                        <span>{item?.icon}</span>
                        <span>{item?.title}</span>
                      </>
                    ) : (
                      <>
                        <span>{item?.title}</span>
                        <span>{item?.icon}</span>
                      </>
                    )}
                  </span>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
