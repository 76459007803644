import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { TextField } from "@mui/material";
import dateUtils from "../../../../../utils/dateUtils";

export default function MobileNumberAndDateOfBirth() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="mobileNumber"
          name="mobileNumber"
          autoComplete="mobileNumber"
          autoFocus
          error={!!editEmployeeErrors?.mobileNumber}
          helperText={editEmployeeErrors?.mobileNumber}
          value={editEmployeeForm?.mobileNumber}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="text"
          label={t("employees.mobileNumber")}
          placeholder="e.g. +971526775643"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="dateOfBirth"
          name="dateOfBirth"
          autoComplete="dateOfBirth"
          autoFocus
          error={!!editEmployeeErrors?.dateOfBirth}
          helperText={editEmployeeErrors?.dateOfBirth}
          value={editEmployeeForm?.dateOfBirth === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.dateOfBirth}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="date"
          label={t("employees.dateOfBirth")}
          placeholder="e.g. 2020-02-23"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
