import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { TextField } from "@mui/material";

export default function CompanyNameAndAddress() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="companyName"
          name="companyName"
          autoComplete="companyName"
          autoFocus
          error={!!editEmployeeErrors?.companyName}
          helperText={editEmployeeErrors?.companyName}
          value={editEmployeeForm?.companyName}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="text"
          label={t("employees.companyName")}
          placeholder="e.g. eitmam land"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="companyAddress"
          name="companyAddress"
          autoComplete="companyAddress"
          autoFocus
          error={!!editEmployeeErrors?.companyAddress}
          helperText={editEmployeeErrors?.companyAddress}
          value={editEmployeeForm?.companyAddress}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="text"
          label={t("employees.companyAddress")}
          placeholder="e.g.  baniyas, abu dhabi"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
