import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import {
  setShowConfirmPassword,
  setShowPassword,
} from "../../../../redux/reduxSlice/globalSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { handleNewEmployeeInputChange } from "../../../../libs/employees/addNewEmployee/addNewEmployeeFunctions";

export default function Password() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    newEmployeeForm,
    newEmployeeErrors,
    employees,
  } = useSelector((state) => state.employees);
  const {
    showPassword,
    showConfirmPassword,
  } = useSelector((state) => state.global);
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="password"
          name="password"
          autoComplete="password"
          autoFocus
          error={!!newEmployeeErrors?.password}
          helperText={newEmployeeErrors?.password}
          value={newEmployeeForm?.password}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type={showPassword ? "text" : "password"}
          label={t("global.password")}
          placeholder="e.g. test@1244"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => dispatch(setShowPassword(!showPassword))}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          autoComplete="confirmPassword"
          autoFocus
          error={!!newEmployeeErrors?.confirmPassword}
          helperText={newEmployeeErrors?.confirmPassword}
          value={newEmployeeForm?.confirmPassword}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type={showConfirmPassword ? "text" : "password"}
          label={t("global.confirmPassword")}
          placeholder="e.g. test@1244"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    dispatch(setShowConfirmPassword(!showConfirmPassword))
                  }
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
}
