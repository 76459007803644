import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import logo from "../../../../../assets/images/logo.png";

export default function EmploymentAndApprovedContract() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={
              user?.employmentContract === undefined
                ? logo
                : user?.employmentContract
            }
            alt={t("employees.employmentContract")}
            style={{ width: 100, height: 100 }}
          />
          <h3>{t("employees.employmentContract")}</h3>
        </div>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={
              user?.approvedContract === undefined
                ? logo
                : user?.approvedContract
            }
            alt={t("employees.approvedContract")}
            style={{ width: 100, height: 100 }}
          />
          <h3>{t("employees.approvedContract")}</h3>
        </div>
      </div>
    </>
  );
}
