import React, { useState } from "react";
import i18n from "../../../assets/i18n/i18n";
import { MdMenu } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleLanguageChange } from "../../../utils/changeLanguage";

export default function DrawerNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  const drawerNav = [
    {
      id: 1,
      title: t("header.home"),
      onClick: () => navigate("/"),
    },
    {
      id: 2,
      title: t("header.about"),
      onClick: () => navigate("/about"),
    },
    {
      id: 3,
      title: t("header.services"),
      onClick: () => navigate("/services"),
    },
    {
      id: 4,
      title: t("header.contact"),
      onClick: () => navigate("/contact"),
    },
    {
      id: 5,
      title: t("header.language"),
      onClick: () => handleLanguageChange(),
    },
    {
      id: 6,
      title: t("header.login"),
      onClick: () => navigate("/login"),
    },
  ];

  return (
    <>
      {i18n.language === "en" ? (
        <>
          <div className="drawer">
            <MdMenu
              size={25}
              style={{ display: drawer ? "none" : "flex" }}
              onClick={handleDrawer}
            />
            <div className={`drawer-inner ${drawer ? "open" : ""}`}>
              <div className="drawer-close">
                <IoClose size={35} onClick={handleDrawer} />
              </div>
              <div className="drawer-content">
                {drawerNav?.map((item) => {
                  return (
                    <>
                      <span
                        key={item?.id}
                        className="drawer-content-item"
                        onClick={item?.onClick}
                      >
                        {i18n.language === "en" ? (
                          <>
                            <span>{item?.icon}</span>
                            <span>{item?.title}</span>
                          </>
                        ) : (
                          <>
                            <span>{item?.title}</span>
                            <span>{item?.icon}</span>
                          </>
                        )}
                      </span>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="drawer">
            <MdMenu
              size={25}
              style={{ display: drawer ? "none" : "flex" }}
              onClick={handleDrawer}
            />
            <div className={`drawer-inner1 ${drawer ? "open" : ""}`}>
              <div className="drawer-close1">
                <IoClose size={35} onClick={handleDrawer} />
              </div>
              <div className="drawer-content">
                {drawerNav?.map((item) => {
                  return (
                    <>
                      <span
                        key={item?.id}
                        className="drawer-content-item"
                        style={{
                          justifyContent: "flex-end",
                        }}
                        onClick={item?.onClick}
                      >
                        {i18n.language === "en" ? (
                          <>
                            <span>{item?.icon}</span>
                            <span>{item?.title}</span>
                          </>
                        ) : (
                          <>
                            <span>{item?.title}</span>
                            <span>{item?.icon}</span>
                          </>
                        )}
                      </span>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
