import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";

export default function RoleAndGender() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <FormControl fullWidth sx={{ margin: "0.5rem" }}>
          <InputLabel id="role">{t("employees.role")}</InputLabel>
          <Select
            size="small"
            placeholder="e.g. user"
            labelId="role"
            required
            fullWidth
            id="role"
            name="role"
            autoComplete="role"
            autoFocus
            error={!!editEmployeeErrors?.role}
            helperText={editEmployeeErrors?.role}
            value={editEmployeeForm?.role !== undefined ? editEmployeeForm?.role : "Select a role"}
            label={t("employees.role")}
            onChange={(e) =>
              handleEditEmployeeInputChange(
                e,
                editEmployeeForm,
                dispatch, t, employees
              )
            }
          >
            <MenuItem value={"user"}>{t("employees.user")}</MenuItem>
            <MenuItem value={"hr"}>{t("employees.hr")}</MenuItem>
            <MenuItem value={"supervisor"}>{t("employees.supervisor")}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ margin: "0.5rem" }}>
          <InputLabel id="gender">{t("employees.gender")}</InputLabel>
          <Select
            size="small"
            placeholder="e.g. male"
            labelId="gender"
            required
            fullWidth
            id="gender"
            name="gender"
            autoComplete="gender"
            autoFocus
            error={!!editEmployeeErrors?.gender}
            helperText={editEmployeeErrors?.gender}
            value={editEmployeeForm?.gender !== undefined ? editEmployeeForm?.gender : "Select a gender"}
            label={t("employees.gender")}
            onChange={(e) =>
              handleEditEmployeeInputChange(
                e,
                editEmployeeForm,
                dispatch, t, employees
              )
            }
          >
            <MenuItem value={"male"}>{t("employees.male")}</MenuItem>
            <MenuItem value={"female"}>{t("employees.female")}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
}
