import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import dateUtils from "../../../../../utils/dateUtils";

export default function PhoneNumberAndDateOfJoining() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();

  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="phoneNumber"
          name="phoneNumber"
          autoComplete="phoneNumber"
          autoFocus
          error={!!editEmployeeErrors?.phoneNumber}
          helperText={editEmployeeErrors?.phoneNumber}
          value={editEmployeeForm?.phoneNumber === undefined ? "" : editEmployeeForm?.phoneNumber}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="text"
          label={t("employees.phoneNumber")}
          placeholder="e.g. +971526775643"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="dateOfJoining"
          name="dateOfJoining"
          autoComplete="dateOfJoining"
          autoFocus
          error={!!editEmployeeErrors?.dateOfJoining}
          helperText={editEmployeeErrors?.dateOfJoining}
          value={editEmployeeForm?.dateOfJoining === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.dateOfJoining}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="date"
          label={t("employees.dateOfJoining")}
          placeholder="e.g. 2020-02-23"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
