import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, TextField } from "@mui/material";
import logo from "../../../../../assets/images/logo.png";
import { useSelector } from "react-redux";

export default function ResidencyNumberAndImage() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={
              user?.residencyImage === undefined ? logo : user?.residencyImage
            }
            alt={t("employees.residencyImage")}
            style={{ width: 100, height: 100 }}
          />
          <h3>{t("employees.residencyImage")}</h3>
        </div>
        <TextField
          fullWidth
          value={user?.residencyNumber}
          size="small"
          type="text"
          label={t("employees.residencyNumber")}
          placeholder="e.g.  123878687"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
