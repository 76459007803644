import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleNewEmployeeInputChange } from "../../../../libs/employees/addNewEmployee/addNewEmployeeFunctions";
import { TextField } from "@mui/material";

export default function AnnualLeaves() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newEmployeeForm, newEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="remainingAnnualLeaves"
          name="remainingAnnualLeaves"
          autoComplete="remainingAnnualLeaves"
          autoFocus
          error={!!newEmployeeErrors?.remainingAnnualLeaves}
          helperText={newEmployeeErrors?.remainingAnnualLeaves}
          value={newEmployeeForm?.remainingAnnualLeaves}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type="number"
          label={t("employees.remainingAnnualLeaves")}
          placeholder="e.g. 15"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          required
          fullWidth
          id="remainingSickLeaves"
          name="remainingSickLeaves"
          autoComplete="remainingSickLeaves"
          autoFocus
          error={!!newEmployeeErrors?.remainingSickLeaves}
          helperText={newEmployeeErrors?.remainingSickLeaves}
          value={newEmployeeForm?.remainingSickLeaves}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type="number"
          label={t("employees.remainingSickLeaves")}
          placeholder="e.g. 10"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
