const user = "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2Fuser.png?alt=media&token=218858f4-cf10-495b-9d1d-b51f3072aa8b"
const logo = "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2Flogo.jpg?alt=media&token=bfca744e-790e-47cf-abdb-caa144d87efe"
export { user, logo };

// 0
// : 
// "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2FbgRemoveLogo.png?alt=media&token=86c55a9a-0909-40ff-9d8f-1bd5d660c84e"
// 1
// : 
// "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2FbgRemoveLogo2.png?alt=media&token=bd809fac-f980-416e-bf07-b27d983f2819"
// 2
// : 
// "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2Flogo.jpg?alt=media&token=bfca744e-790e-47cf-abdb-caa144d87efe"
// 3
// : 
// "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2Flogo2.jpg?alt=media&token=8a93049b-3a71-45b8-91ba-38a7e5c6edb7"
// 4
// : 
// "https://firebasestorage.googleapis.com/v0/b/eitmamland-9c301.appspot.com/o/hardCodedImages%2Fuser.png?alt=media&token=218858f4-cf10-495b-9d1d-b51f3072aa8b"
// length
// : 
// 5