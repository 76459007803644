import React from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/reduxSlice/userSlice";
import { useTranslation } from "react-i18next";
import i18n from "../../assets/i18n/i18n";
import { handleLanguageChange } from "../../utils/changeLanguage";
import "../../assets/styles/Header.css";
import DrawerSidebar from "../../components/global/drawerSidebar/DrawerSidebar";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(setUser(null));
    localStorage.clear();
    message.success(
      i18n.language === "en" ? (
        <>{t("header.logoutSuccess")}</>
      ) : (
        <>{t("header.logoutSuccess")}</>
      )
    );
    navigate("/");
  };

  const menuData = [
    {
      id: 1,
      title: i18n.language === "en" ? t("header.home") : t("header.language"),
      onClick:
        i18n.language === "en"
          ? () => user?.role === "user" ? navigate("/eitmamland/user") : user?.role === "hr" ? navigate("/eitmamland/hr") : navigate("/eitmamland/supervisor")
          : () => handleLanguageChange(),
    },
    {
      id: 2,
      title: i18n.language === "en" ? t("header.about") : t("header.contact"),
      onClick:
        i18n.language === "en"
          ? () => navigate("/about")
          : () => navigate("/contact"),
    },
    {
      id: 3,
      title: t("header.services"),
      onClick: () => navigate("/services"),
    },
    {
      id: 4,
      title: i18n.language === "en" ? t("header.contact") : t("header.about"),
      onClick:
        i18n.language === "en"
          ? () => navigate("/contact")
          : () => navigate("/about"),
    },
    {
      id: 5,
      title: i18n.language === "en" ? t("header.language") : t("header.home"),
      onClick:
        i18n.language === "en"
          ? () => handleLanguageChange()
          : () => user?.role === "user" ? navigate("/eitmamland/user") : user?.role === "hr" ? navigate("/eitmamland/hr") : navigate("/eitmamland/supervisor"),
    },
  ];

  return (
    <>
      {i18n.language === "en" ? (
        <>
          {/* english */}
          <div className="header-bottom header-bottom-fix">
            <div className="logo" onClick={() => navigate("/")}>
              {t("header.name")}
            </div>
            <div className="menu">
              {menuData?.map((item) => {
                return (
                  <>
                    <span
                      key={item?.id}
                      className="menu-item"
                      onClick={item?.onClick}
                    >
                      {item?.title}
                    </span>
                  </>
                );
              })}
            </div>
            <div className="language-selector">
              <span className="menu-item" onClick={handleLogout}>
                {t("header.logout")}
              </span>
            </div>
            <DrawerSidebar handleLogout={handleLogout} />
          </div>
        </>
      ) : (
        <>
          {/* arabic */}
          <div className="header-bottom header-bottom-fix">
            <DrawerSidebar handleLogout={handleLogout} />
            <div className="language-selector">
              <span className="menu-item" onClick={handleLogout}>
                {t("header.logout")}
              </span>
            </div>
            <div className="menu">
              {menuData?.map((item) => {
                return (
                  <>
                    <span
                      key={item?.id}
                      className="menu-item"
                      onClick={item?.onClick}
                    >
                      {item?.title}
                    </span>
                  </>
                );
              })}
            </div>
            <div className="logo" onClick={() => navigate("/")}>
              {t("header.name")}
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Header;
