import { db } from "../../../firebase/firebase";
import { message } from "antd";
import { deleteDoc, doc } from "firebase/firestore";
import { setLoading } from "../../../redux/reduxSlice/globalSlice"
import { deleteFileByURL } from "../../../utils/deleteFileByURL";
import { fetchRequestData, getRequestsByUserId } from "../getRequests/getRequestFunctions";
import { setUserRequests } from "../../../redux/reduxSlice/requestsSlice";


export const deleteRequest = async (data, t, dispatch) => {
    try {
        dispatch(setLoading(true))
        const requestDoc = doc(db, "requests", data?.requestId);
        if (data?.attachments?.length > 0) {
            await Promise.all(data?.attachments?.map(async (item) => await deleteFileByURL(item)));
        }
        await deleteDoc(requestDoc);
        const requests = await getRequestsByUserId(data?.userId)
        dispatch(setUserRequests(requests))
        await fetchRequestData(dispatch)
        message.success(t("requests.deleteRequestSuccess"))
    } catch (error) {
        dispatch(setLoading(false))
        message.error(t("requests.deleteRequestError"))
    }
}