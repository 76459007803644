import React from 'react'
import i18n from "../../../assets/i18n/i18n";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/Employees.css";
import Loading from "../../../components/global/loading/Loading";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, InputAdornment, TextField } from "@mui/material";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaRegFilePdf } from "react-icons/fa";
import { handleNewRequestInputChange, handleRemoveRequestAttachments, handleSubmit } from '../../../libs/requests/addNewRequest/addNewRequestFunctions';

export default function AddNewRequestSupervisor() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { width, loading } = useSelector((state) => state.global);
  const { newRequestForm, newRequestErrors } = useSelector((state) => state.requests);
  const { user } = useSelector((state) => state.user);
  if (loading) return <Loading />;
  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <div className="global-main">
          <Button
            onClick={() => navigate("/eitmamland/supervisor")}
            sx={{
              margin: "0.5rem 0rem",
              background: "#d4df98",
              "&:hover": {
                background: "#a4b964",
              },
            }}
            variant="contained"
          >

            {t("global.dashboardButton")}

          </Button>
        </div>
        {/* heading */}
        <div className="global-main">
          <h2
            className="add-employees-heading"
            style={{
              justifyContent:
                i18n.language !== "en" ? "flex-end" : "flex-start",
            }}
          >
            {t("global.addNewRequest")}
          </h2>
        </div>
        {/* start and end date */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="title"
            name="title"
            autoComplete="title"
            autoFocus
            error={!!newRequestErrors?.title}
            helperText={newRequestErrors?.title}
            value={newRequestForm?.title}
            onChange={(e) =>
              handleNewRequestInputChange(
                e,
                newRequestForm,
                dispatch,
                t
              )
            }
            size="small"
            type="text"
            label={t("requests.title")}
            placeholder="e.g. Request for loan"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        {/* description and attachements */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="description"
            name="description"
            autoComplete="description"
            autoFocus
            error={!!newRequestErrors?.description}
            helperText={newRequestErrors?.description}
            value={newRequestForm?.description}
            onChange={(e) =>
              handleNewRequestInputChange(
                e,
                newRequestForm,
                dispatch,
                t
              )
            }
            size="small"
            type="text"
            multiline
            rows={6}
            label={t("requests.description")}
            placeholder="e.g. request details"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        <div className="global-main">
          <TextField
            type="file"
            fullWidth
            id="attachments"
            name="attachments"
            autoComplete="attachments"
            autoFocus
            label={t("requests.attachments")}
            placeholder="e.g. Attachments"
            sx={{ margin: "0.5rem" }}
            onChange={(e) =>
              handleNewRequestInputChange(
                e,
                newRequestForm,
                dispatch,
                t
              )
            }
            inputProps={{ accept: 'image/*,.pdf' }}
          />
        </div>
        {/* attachments view */}
        {newRequestForm?.attachments?.length > 0 ?
          <>
            {newRequestForm?.attachments?.map((item) => {
              return (
                <>
                  <div className="global-main">
                    <TextField
                      fullWidth
                      sx={{ margin: "0.5rem" }}
                      autoFocus
                      value={item?.name}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            {item?.type === "application/pdf" ? (
                              <Avatar sx={{ background: "#FAF9F6" }}>
                                <FaRegFilePdf size={25} color="red" />
                              </Avatar>
                            ) : (
                              <Avatar
                                src={URL.createObjectURL(item)}
                                alt="Attachment"
                              />
                            )}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IoMdCloseCircleOutline size={25} color='red' cursor="pointer"
                              onClick={() =>
                                handleRemoveRequestAttachments(
                                  dispatch,
                                  newRequestForm,
                                  item,
                                )
                              } />
                          </InputAdornment>
                        ),
                      }} />
                  </div>
                </>

              )
            })}
          </> : ""}

        {/* SubmitButton */}
        <div className="add-employees-btn">
          <Button
            onClick={(e) =>
              handleSubmit(
                e,
                newRequestForm,
                newRequestErrors,
                dispatch,
                navigate,
                t,
                user
              )
            }
            sx={{
              margin: "0.5rem 0rem",
              background: "#d4df98",
              "&:hover": {
                background: "#a4b964",
              },
            }}
            variant="contained"
          >
            {t("global.submit")}
          </Button>
        </div>
      </div >
    </>
  )
}
