import { setEditEmployeeErrors } from "../../../redux/reduxSlice/employeesSlice";
import validationUtils from "../../../utils/validationUtils";

export const validateEditEmployee = (dispatch, name, value, t, employees) => {
    if (name === "englishName") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorEnglishName"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "email") {
        const validEmail = validationUtils?.isValidEmail(value)
        const emailExists = employees?.some(employee => employee.email === value);
        if (!validEmail) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorEmail"),
                    })
                );
            });
        } else if (emailExists) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorEmailExist", { value }), // Adjusted translation
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "phoneNumber") {
        const validPhoneNumber = validationUtils?.isValidPhoneNumber(value)
        if (!validPhoneNumber) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorPhoneNumber"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "remainingAnnualLeaves") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorRemainingAnnualLeaves"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "remainingSickLeaves") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorRemainingSickLeaves"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.editEmployeeErrors;
                dispatch(
                    setEditEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else {
        dispatch((dispatch, getState) => {
            const currentErrors = getState().employees.editEmployeeErrors;
            dispatch(
                setEditEmployeeErrors({
                    ...currentErrors,
                    [name]: "",
                })
            );
        });
    }
};

export const validateRequiredFieldsEditEmployee = (dispatch, editEmployeeForm, editEmployeeErrors, t) => {
    let errors = { ...editEmployeeErrors }
    if (editEmployeeForm.englishName === "") {
        errors.englishName = t("employees.errorEnglishName");
    }
    if (editEmployeeForm.email === "" || !validationUtils.isValidEmail(editEmployeeForm.email)) {
        errors.email = t("employees.errorEmail");
    }
    if (editEmployeeForm.phoneNumber === "" || !validationUtils.isValidPhoneNumber(editEmployeeForm.phoneNumber)) {
        errors.phoneNumber = t("employees.errorPhoneNumber");
    }
    if (editEmployeeForm.remainingAnnualLeaves === "") {
        errors.remainingAnnualLeaves = t("employees.errorRemainingAnnualLeaves");
    }
    if (editEmployeeForm.remainingSickLeaves === "") {
        errors.remainingSickLeaves = t("employees.errorRemainingSickLeaves");
    }
    if (editEmployeeForm.gender === "") {
        errors.gender = t("employees.errorGender");
    }
    if (editEmployeeForm.role === "") {
        errors.role = t("employees.errorRole");
    }
    dispatch(setEditEmployeeErrors(errors));
    return errors
};