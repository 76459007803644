import React from "react";
import { useTranslation } from "react-i18next";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { Avatar } from "@mui/material";
import { logo } from "../../../../../assets/constants/global";
import { AiOutlineCamera } from "react-icons/ai";

export default function EmploymentAndApprovedContract() {
  const dispatch = useDispatch();
  const { editEmployeeForm, employees } = useSelector((state) => state.employees);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <div className="global-main" style={{ flexDirection: "column" }}>
          <div className="image-main-div">
            <div className="image-inner-div">
              <Avatar
                src={editEmployeeForm?.employmentContractNew !== undefined
                  ? URL.createObjectURL(editEmployeeForm?.employmentContractNew) :
                  editEmployeeForm?.employmentContract
                    ? editEmployeeForm?.employmentContract
                    : logo
                }
                alt={t("employees.employmentContract")}
                style={{ width: 100, height: 100 }}
              />
              <label style={{ cursor: "pointer" }}
                htmlFor={editEmployeeForm?.employmentContract !== undefined ? "employmentContract" : "employmentContractNew"}>
                <div style={{ position: "relative" }}>
                  <span>
                    <AiOutlineCamera size={28} className="image-icon" />
                  </span>
                </div>

                <input
                  id={editEmployeeForm?.employmentContract !== undefined ? "employmentContract" : "employmentContractNew"}
                  name={editEmployeeForm?.employmentContract !== undefined ? "employmentContract" : "employmentContractNew"}
                  autoComplete={editEmployeeForm?.employmentContract !== undefined ? "employmentContract" : "employmentContractNew"}
                  autoFocus
                  type="file"
                  hidden
                  size="small"
                  placeholder={t("employees.employmentContract")}
                  onChange={(e) =>
                    handleEditEmployeeInputChange(
                      e,
                      editEmployeeForm,
                      dispatch, t, employees
                    )
                  }
                />
                <br />
              </label>
            </div>
          </div>
          <h3>{t("employees.employmentContract")}</h3>
        </div>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <div className="image-main-div">
            <div className="image-inner-div">
              <Avatar
                src={editEmployeeForm?.approvedContractNew !== undefined
                  ? URL.createObjectURL(editEmployeeForm?.approvedContractNew) :
                  editEmployeeForm?.approvedContract
                    ? editEmployeeForm?.approvedContract
                    : logo
                }
                alt={t("employees.approvedContract")}
                style={{ width: 100, height: 100 }}
              />
              <label style={{ cursor: "pointer" }}
                htmlFor={editEmployeeForm?.approvedContract !== undefined ? "approvedContract" : "approvedContractNew"}>
                <div style={{ position: "relative" }}>
                  <span>
                    <AiOutlineCamera size={28} className="image-icon" />
                  </span>
                </div>

                <input
                  id={editEmployeeForm?.approvedContract !== undefined ? "approvedContract" : "approvedContractNew"}
                  name={editEmployeeForm?.approvedContract !== undefined ? "approvedContract" : "approvedContractNew"}
                  autoComplete={editEmployeeForm?.approvedContract !== undefined ? "approvedContract" : "approvedContractNew"}
                  autoFocus
                  type="file"
                  hidden
                  size="small"
                  placeholder={t("employees.approvedContract")}
                  onChange={(e) =>
                    handleEditEmployeeInputChange(
                      e,
                      editEmployeeForm,
                      dispatch, t, employees
                    )
                  }
                />
                <br />
              </label>
            </div>
          </div>
          <h3>{t("employees.approvedContract")}</h3>
        </div>
      </div>
    </>
  );
}
