import { Button, TextField } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleSubmit } from '../../../libs/responseModal/responseModalFunctions';
import { setResponseModalForm } from '../../../redux/reduxSlice/globalSlice';
import { useNavigate } from 'react-router-dom';

export default function ResponseModalLeave({ state }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { responseModalForm, responseModal
    } = useSelector((state) => state.global);
    const { user } = useSelector((state) => state.user);
    return (
        <>
            <div className='global-main-outer'>
                <h1>
                    {responseModal?.response === "approved" ?
                        <>
                            {t("leaves.approveLeave")}
                        </> : <>
                            {t("leaves.rejectLeave")}
                        </>}
                </h1>
                <div className="global-main" style={{ flexDirection: "column" }}>
                    <TextField
                        fullWidth
                        id="comments"
                        name="comments"
                        autoComplete="comments"
                        autoFocus
                        value={responseModalForm}
                        onChange={(e) => dispatch(setResponseModalForm(e.target.value))
                        }
                        multiline
                        rows={6}
                        size="small"
                        type="text"
                        label={t("global.responseModal")}
                        placeholder="e.g. response details..."
                        sx={{ margin: "0.5rem" }}
                    />
                </div>
                <Button
                    onClick={
                        (e) => handleSubmit(e, responseModalForm, responseModal,
                            dispatch, t, state, navigate, user)
                    }
                    sx={{
                        margin: "0.5rem 0rem",
                        background: responseModal?.response === "approved" ? "#d4df98" : "#f44336",
                        "&:hover": {
                            background: responseModal?.response === "approved" ? "#a4b964" : "#d32f2f",
                        },
                    }}
                    variant="contained"
                >
                    {responseModal?.response === "approved" ?
                        <>
                            {t("global.approve")}
                        </> : <>
                            {t("global.reject")}
                        </>}
                </Button>
            </div>
        </>
    )
}
