import React from "react";
import { useTranslation } from "react-i18next";
import dateUtils from "../../../../../utils/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { TextField } from "@mui/material";

export default function ResidencyIssueAndExpiry() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="residencyIssue"
          name="residencyIssue"
          autoComplete="residencyIssue"
          autoFocus
          error={!!editEmployeeErrors?.residencyIssue}
          helperText={editEmployeeErrors?.residencyIssue}
          value={editEmployeeForm?.residencyIssue === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.residencyIssue}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.residencyIssue")}
          placeholder="e.g.  18/02/2020"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="residencyExpiry"
          name="residencyExpiry"
          autoComplete="residencyExpiry"
          autoFocus
          error={!!editEmployeeErrors?.residencyExpiry}
          helperText={editEmployeeErrors?.residencyExpiry}
          value={editEmployeeForm?.residencyExpiry === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.residencyExpiry}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.residencyExpiry")}
          placeholder="e.g. 18/02/2024"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
