import React from "react";
import Sidebar from "./sidebar/Sidebar";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import "../assets/styles/Layout.css";

const AppLayout = ({ children }) => {
  return (
    <>
      <div className="layout-main">
        <Header />
        <div className="layout-main-inner">
          <Sidebar />
          <div className="layout-main">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default AppLayout;
