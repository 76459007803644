import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { useNavigate } from 'react-router-dom';

const SickLeavesBarChartHr = ({ sickLeavesData }) => {
    const [chartData, setChartData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const processData = () => {
            // Determine the range of years from the leave data
            const years = sickLeavesData.reduce((acc, leave) => {
                const leaveStartYear = new Date(leave.startDate).getFullYear();
                if (!acc.includes(leaveStartYear)) {
                    acc.push(leaveStartYear);
                }
                return acc;
            }, []);

            // Create a mapping of months to leave counts and leave days
            const data = sickLeavesData.reduce((acc, leave) => {
                const leaveStartDate = new Date(leave.startDate);
                const leaveMonthYear = leaveStartDate.toLocaleString('default', { month: 'short', year: 'numeric' });

                // Calculate the number of leave days
                const leaveEndDate = new Date(leave.endDate);
                const leaveDays = Math.ceil((leaveEndDate - leaveStartDate) / (1000 * 60 * 60 * 24)) + 1;

                if (!acc[leaveMonthYear]) {
                    acc[leaveMonthYear] = { month: leaveMonthYear, leavesApplied: 0, totalLeaveDays: 0 };
                }

                acc[leaveMonthYear].leavesApplied += 1;
                acc[leaveMonthYear].totalLeaveDays += leaveDays;

                return acc;
            }, {});

            // Generate months dynamically for each year present in the data
            const completeData = [];
            years.forEach(year => {
                for (let i = 0; i < 12; i++) {
                    const date = new Date(year, i, 1);
                    const monthYear = date.toLocaleString('default', { month: 'short', year: 'numeric' });
                    completeData.push(data[monthYear] || { month: monthYear, leavesApplied: 0, totalLeaveDays: 0 });
                }
            });

            setChartData(completeData);
        };

        processData();
    }, [sickLeavesData]);

    // Handle bar click event
    const handleBarClick = (data) => {
        const monthYear = data.payload.month; // Get month-year from the clicked bar data
        navigate(`/eitmamland/hr/monthlySickLeave/${monthYear}`); // Navigate to the desired page with query params
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month">
                    <Label value="Month" offset={0} position="insideBottom" />
                </XAxis>
                <YAxis>
                    <Label value="Days" angle={-90} position="insideLeft" />
                </YAxis>
                <Tooltip />
                <Legend />
                <Bar
                    dataKey="leavesApplied"
                    fill="#8884d8"
                    name="Leaves Applied"
                    onClick={handleBarClick} // Add click event handler
                    style={{ cursor: 'pointer' }}
                />
                <Bar
                    dataKey="totalLeaveDays"
                    fill="#82ca9d"
                    name="Total Leave Days"
                    onClick={handleBarClick} // Add click event handler
                    style={{ cursor: 'pointer' }}
                />
                {chartData.length === 0 && (
                    <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        fill="#888"
                        fontSize="16px"
                        dy={8}
                    >
                        No record found
                    </text>
                )}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SickLeavesBarChartHr;
