import React from "react";
import i18n from "../../../assets/i18n/i18n";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/Employees.css";
import Loading from "../../../components/global/loading/Loading";
import NameAndEmail from "../../../components/global/employees/addNewEmployees/NameAndEmail";
import PhoneNumberAndDateOfJoining from "../../../components/global/employees/addNewEmployees/PhoneNumberAndDateOfJoining";
import AnnualLeaves from "../../../components/global/employees/addNewEmployees/AnnualLeaves";
import Password from "../../../components/global/employees/addNewEmployees/Password";
import RoleAndGender from "../../../components/global/employees/addNewEmployees/RoleAndGender";
import SubmitButton from "../../../components/global/employees/addNewEmployees/SubmitButton";
import TopButtons from "../../../components/global/employees/addNewEmployees/TopButtons";

export default function AddNewEmployees() {
  const { t } = useTranslation();
  const { width, loading } = useSelector((state) => state.global);
  if (loading) return <Loading />;
  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <TopButtons />
        {/* heading */}
        <div className="global-main">
          <h2
            className="add-employees-heading"
            style={{
              justifyContent:
                i18n.language !== "en" ? "flex-end" : "flex-start",
            }}
          >
            {t("employees.newEmployeeHeading")}
          </h2>
        </div>
        {/* NameAndEmail */}
        <NameAndEmail />
        {/* PhoneNumberAndDateOfJoining */}
        <PhoneNumberAndDateOfJoining />
        {/* AnnualLeaves */}
        <AnnualLeaves />
        {/* Password */}
        <Password />
        {/* RoleAndGender */}
        <RoleAndGender />
        {/* SubmitButton */}
        <SubmitButton />
      </div>
    </>
  );
}
