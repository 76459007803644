import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import i18n from "../assets/i18n/i18n";

export function Copyright({ sx, t, color }) {
  return (
    <Typography variant="body2" color={color} align="center" sx={sx}>
      {"Copyright © "}
      <Link style={{ color: color }} to="/">
        {i18n.language === "en" ? (
          <>{t("login.name")}</>
        ) : (
          <>{t("login.name")}</>
        )}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
