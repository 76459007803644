import React, { useEffect } from "react";
import CarouselComponent from "../../../components/global/carousel/CarouselComponent";
import Footer from "../../../components/global/footer/Footer";
import Header from "../../../components/global/header/Header";
import WhatsAppButton from "../../../components/global/whatsappButton/WhatsappButton";
import ContactusForm from "../../../components/global/contactusForm/ContactusForm";
import { useTranslation } from "react-i18next";
import i18n from "../../../assets/i18n/i18n";
import "../../../assets/styles/Services.css"
import { scrollToTop } from "../../../utils/scrollToTop";

export default function Contact() {
  const { t } = useTranslation();
  useEffect(() => {
    scrollToTop();   // eslint-disable-next-line
  }, [])
  return (
    <>
      <Header />
      <CarouselComponent />
      <ContactusForm />
      <div class="container">
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          {t("contactus.tagline")}
        </div>
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          <h3>{t("contactus.name")}</h3>
        </div>
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          {t("contactus.address1")}
        </div>
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          {t("contactus.address2")}
        </div>
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          {t("contactus.address3")}
        </div>
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          {i18n.language === "en" ?
            <>
              {t("contactus.phone")}:  +971-2-5833982
            </> : <>
              +971-2-5833982  :{t("contactus.phone")}
            </>}
        </div>
        <div className="global-flex-class" style={{ justifyContent: i18n.language === "en" ? "flex-start" : "flex-end", }}>
          <a
            style={{ color: "black" }}
            href="mailto:info@eitmamtasheel.ae"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.language === "en" ?
              <>
                {t("contactus.email")}: info@eitmamtasheel.ae
              </> : <>
                info@eitmamtasheel.ae :{t("contactus.email")}
              </>}
          </a>
        </div>
      </div>
      <Footer />
      <WhatsAppButton />
    </>
  );
}
