import { setNewRequestErrors, setNewRequestForm, clearNewRequestForm, setUserRequests } from "../../../redux/reduxSlice/requestsSlice";
import { setLoading } from "../../../redux/reduxSlice/globalSlice";
import { db } from "../../../firebase/firebase";
import { addDoc, collection, setDoc } from "firebase/firestore";
import dateUtils from "../../../utils/dateUtils";
import { message } from "antd";
import { fetchRequestData, getRequestsByUserId } from "../getRequests/getRequestFunctions";
import { uploadImage } from "../../../utils/uploadImageToFirebase";
// import { sendNotification } from "../../../utils/pushNotificationFunc";
import { sendEmail } from "../../../utils/sendEmail";

const validateAddNewRequest = (dispatch, name, value, t) => {
    if (name === "title") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().requests.newRequestErrors;
                dispatch(
                    setNewRequestErrors({
                        ...currentErrors,
                        [name]: t("requests.errorTitle"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().requests.newRequestErrors;
                dispatch(
                    setNewRequestErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "description") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().requests.newRequestErrors;
                dispatch(
                    setNewRequestErrors({
                        ...currentErrors,
                        [name]: t("requests.errorDescription"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().requests.newRequestErrors;
                dispatch(
                    setNewRequestErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else {
        dispatch((dispatch, getState) => {
            const currentErrors = getState().requests.newRequestErrors;
            dispatch(
                setNewRequestErrors({
                    ...currentErrors,
                    [name]: "",
                })
            );
        });
    }
};

const validateAllFieldsAddNewRequest = (dispatch, newRequestForm, newRequestErrors, t) => {
    let errors = { ...newRequestErrors }
    if (newRequestForm.title === "") {
        errors.endDate = t("requests.errorTitle");
    }
    if (newRequestForm.description === "") {
        errors.description = t("requests.errorDescription");
    }
    dispatch(setNewRequestErrors(errors));
    return errors
};

export const handleNewRequestInputChange = (event, newRequestForm, dispatch, t) => {
    const { name, value, files } = event.target;
    if (name === "attachments") {
        if (newRequestForm?.attachments?.length > 0) {
            const attachment = newRequestForm?.attachments;
            if (files[0]) {
                dispatch(setNewRequestForm({ ...newRequestForm, [name]: [...attachment, files[0]] }));
            }
        } else {
            if (files[0]) {
                dispatch(setNewRequestForm({ ...newRequestForm, [name]: [files[0]] }));
            }
        }
    } else {
        dispatch(setNewRequestForm({ ...newRequestForm, [name]: value }));
        validateAddNewRequest(dispatch, name, value, t, newRequestForm);
    }
};

export const handleRemoveRequestAttachments = (dispatch, newRequestForm, item) => {
    const newAttachments = newRequestForm?.attachments?.filter((file) => file !== item)
    dispatch(setNewRequestForm({ ...newRequestForm, attachments: [...newAttachments] }));
}


export const handleSubmit = async (e, newRequestForm, newRequestErrors, dispatch, navigate, t, user) => {
    e.preventDefault();
    const errors = validateAllFieldsAddNewRequest(dispatch, newRequestForm, newRequestErrors, t);
    if (Object.values(errors).some((error) => error !== "")) {
        message.error(t("global.errorForm"));
        return;
    } else if (Object.values(newRequestErrors).length === 0) {
        message.error(t("global.errorForm"));
        return;
    }
    else {
        try {
            dispatch(setLoading(true));
            await handleAddNewRequest(newRequestForm, navigate, dispatch, t, user);
        } catch (error) {
            dispatch(clearNewRequestForm());
            dispatch(setLoading(false))
            message.error(t("requests.requestError"));
        }
    }
}

const userRef = collection(db, "requests");

const handleAddNewRequest = async (newRequestForm, navigate, dispatch, t, user) => {
    try {
        let attachment = [];
        if (newRequestForm?.attachments?.length > 0) {
            const uploadPromises = newRequestForm.attachments.map((item) =>
                uploadImage(
                    "requests",
                    item,
                    `user-${user?.userId}-${Math.random(1000)}`
                )
            );
            try {
                attachment = await Promise.all(uploadPromises);
            } catch (error) {
                message.error(error.message);
            }
        }
        const docRef = await addDoc(userRef, {
            ...user,
            attachments: attachment,
            title: newRequestForm?.title,
            description: newRequestForm?.description,
            hrView: false,
            supervisorView: false,
            hrStatus: "pending",
            supervisorStatus: "pending",
            hrComments: "",
            supervisorComments: "",
            createdAt: dateUtils.getCurrentDate(),
            updatedAt: dateUtils.getCurrentDate()
        });
        await setDoc(docRef, {
            requestId: docRef.id,
            updatedAt: dateUtils?.getCurrentDate()
        }, { merge: true });
        const userRequests = await getRequestsByUserId(user?.userId)
        setUserRequests(userRequests)
        // await sendNotification("request", newRequestForm?.description, user?.englishName)
        await sendEmail("request", newRequestForm, user)
        await fetchRequestData(dispatch);
        if (user?.role === "user") {
            navigate("/eitmamland/user/requests");
        } else if (user?.role === "hr") {
            navigate("/eitmamland/hr/requests");
        } else {
            navigate("/eitmamland/supervisor/requests");
        }
        message.success(t("requests.requestSuccess"));
        dispatch(setLoading(false));
        dispatch(clearNewRequestForm());
    } catch (error) {
        throw new Error(error);
    }
}