import React from 'react';
import { FaWhatsappSquare } from "react-icons/fa";
import "../../../assets/styles/LandingPage.css"

const WhatsAppButton = () => {

    return (
        <div className='whatsapp-button'>
            <a href="https://wa.me/+97125833982" target="_blank" rel="noopener noreferrer">
                <FaWhatsappSquare className='icon' />
            </a>
        </div>
    );
};



export default WhatsAppButton;
