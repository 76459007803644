import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";

export default function PhoneNumberAndDateOfJoining() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={state?.phoneNumber}
          size="small"
          type="text"
          label={t("employees.phoneNumber")}
          placeholder="e.g. +971526775643"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={state?.dateOfJoining}
          size="small"
          label={t("employees.dateOfJoining")}
          placeholder="e.g. 2020-02-23"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
