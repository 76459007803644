import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Avatar } from "@mui/material";
import { logo } from "../../../../../assets/constants/global";
import { handleOpenImage } from "../../../../../utils/openImage";

export default function EmploymentAndApprovedContract() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={
              state?.employmentContract === undefined
                ? logo
                : state?.employmentContract
            }
            alt={t("employees.employmentContract")}
            style={{ width: 100, height: 100 }}
            onClick={() => handleOpenImage(state?.employmentContract === undefined ? logo : state?.employmentContract)}
          />
          <h3>{t("employees.employmentContract")}</h3>
        </div>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={
              state?.approvedContract === undefined
                ? logo
                : state?.approvedContract
            }
            alt={t("employees.approvedContract")}
            style={{ width: 100, height: 100 }}
            onClick={() => handleOpenImage(state?.approvedContract === undefined ? logo : state?.approvedContract)}
          />
          <h3>{t("employees.approvedContract")}</h3>
        </div>
      </div>
    </>
  );
}
