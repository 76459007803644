import React from "react";
import InsuranceNumberAndImage from "./InsuranceNumberAndImage";
import InsuranceIssueAndExpiry from "./InsuranceIssueAndExpiry";

export default function InsuranceDetails() {
  return (
    <>
      {/* InsuranceNumberAndImage */}
      <InsuranceNumberAndImage />
      {/* InsuranceIssueAndExpiry */}
      <InsuranceIssueAndExpiry />
    </>
  );
}
