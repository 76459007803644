import React from "react";
import EnglishNameAndEmail from "./EnglishNameAndEmail";
import PhoneNumberAndDateOfJoining from "./PhoneNumberAndDateOfJoining";
import AnnualLeaves from "./AnnualLeaves";
import RoleAndGender from "./RoleAndGender";

export default function BasicDetails() {
  return (
    <>
      {/* EnglishNameAndEmail */}
      <EnglishNameAndEmail />
      {/* PhoneNumberAndDateOfJoining */}
      <PhoneNumberAndDateOfJoining />
      {/* AnnualLeaves */}
      <AnnualLeaves />
      {/* RoleAndGender */}
      <RoleAndGender />
    </>
  );
}
