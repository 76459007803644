import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";

export default function PassportIssueAndExpiry() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={state?.passportIssue}
          size="small"
          type="text"
          label={t("employees.passportIssue")}
          placeholder="e.g.  18/05/2023"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={state?.passportExpiry}
          size="small"
          label={t("employees.passportExpiry")}
          placeholder="e.g. 18/05/2028"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
