import { Button } from "@mui/material";
import React from "react";
import i18n from "../../../../assets/i18n/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEditEmployeeForm } from "../../../../redux/reduxSlice/employeesSlice";

export default function TopButtons() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <div className="global-main">
        <Button
          onClick={
            i18n.language === "en"
              ? user?.role === "hr" ? () => navigate("/eitmamland/hr/employees") : () => navigate("/eitmamland/supervisor/employees")
              : () => {
                user?.role === "hr" ? navigate("/eitmamland/hr/editEmployee") : navigate("/eitmamland/supervisor/editEmployee");
                dispatch(setEditEmployeeForm(state))
              }
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("employees.backToEmployees")}</>
          ) : (
            <>{t("employees.editEmployee")}</>
          )}
        </Button>
        <Button
          onClick={
            i18n.language === "en"
              ? () => {
                user?.role === "hr" ? navigate("/eitmamland/hr/editEmployee") : navigate("/eitmamland/supervisor/editEmployee");
                dispatch(setEditEmployeeForm(state))
              }
              : user?.role === "hr" ? () => navigate("/eitmamland/hr/employees") : () => navigate("/eitmamland/supervisor/employees")
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("employees.editEmployee")}</>
          ) : (
            <>{t("employees.backToEmployees")}</>
          )}
        </Button>
      </div>
    </>
  );
}
