import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { setSearch } from '../../../redux/reduxSlice/globalSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/global/loading/Loading';
import i18n from '../../../assets/i18n/i18n';
import { Button } from '@mui/material';
import TopButtons from '../../../components/hr/requestsHr/requestsHrComponents/TopButtons';
import { filterLeavesByMonthAndYear } from '../../../libs/leaves/getLeaves/getLeavesFunctions';
import conversionUtils from '../../../utils/conversionUtils';
import stringUtils from '../../../utils/stringUtils';

export default function RequestsByMonthHr() {
    const { month } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { search, loading, width } = useSelector((state) => state.global);
    const { requests } = useSelector((state) => state.requests);
    const [requestState, setRequestState] = useState([])
    useEffect(() => {
        const data = stringUtils?.splitStringBySpace(month)
        const monthNumber = conversionUtils?.monthNameToNumber(data[0])
        const requestsData = filterLeavesByMonthAndYear(requests, monthNumber, data[1])
        setRequestState(requestsData)
        dispatch(setSearch(""));// eslint-disable-next-line
    }, [month])

    const filteredRequests = requestState.filter((item) => {
        const searchFieldLower = search.toLowerCase();
        return (
            (item.englishName &&
                item.englishName.toLowerCase().includes(searchFieldLower)) ||
            (item.role && item.role.toLowerCase().includes(searchFieldLower)) ||
            (item.email && item.email.toLowerCase().includes(searchFieldLower)) ||
            (item.phoneNumber &&
                item.phoneNumber.toLowerCase().includes(searchFieldLower))
            ||
            (item.title &&
                item.title.toLowerCase().includes(searchFieldLower))
        );
    });
    const columns = [
        {
            title: t("requests.tableColOneTitle"),
            dataIndex: t("requests.tableKeyOne"),
            sorter: (a, b) => a.englishName.localeCompare(b.englishName),
        },
        {
            title: t("requests.tableColTwoTitle"),
            dataIndex: t("requests.tableKeyTwo"),
            sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
            title: t("requests.tableColThreeTitle"),
            dataIndex: t("requests.tableKeyThree"),
            sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
            title: t("requests.tableColFourTitle"),
            dataIndex: t("requests.tableKeyFour"),
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        },
        {
            title: t("requests.tableColFiveTitle"),
            dataIndex: t("requests.tableKeyFive"),
            sorter: (a, b) => a.hrStatus.localeCompare(b.hrStatus),
            render: (text) => {
                let statusClass = "";
                if (text === 'approved') statusClass = "status-approved";
                if (text === 'rejected') statusClass = "status-rejected";
                if (text === 'pending') statusClass = "status-pending";
                return <span className={statusClass}>{text}</span>;
            }
        },
        {
            title: t("requests.tableColSixTitle"),
            dataIndex: t("requests.tableKeySix"),
            sorter: (a, b) => a.supervisorStatus.localeCompare(b.supervisorStatus),
            render: (text) => {
                let statusClass = "";
                if (text === 'approved') statusClass = "status-approved";
                if (text === 'rejected') statusClass = "status-rejected";
                if (text === 'pending') statusClass = "status-pending";
                return <span className={statusClass}>{text}</span>;
            }
        },
        {
            title: t("requests.tableColSevenTitle"),
            dataIndex: t("requests.tableKeySeven"),
            render: (_, data) => (
                <>
                    <Button
                        onClick={() => navigate("/eitmamland/hr/viewRequests", { state: data })}
                        sx={{
                            margin: "0.5rem",
                            background: "#4caf50",
                            "&:hover": {
                                background: "#388e3c",
                            },
                        }}
                        variant="contained"
                        size="small"
                    >
                        {t("requests.viewButton")}
                    </Button>
                </>
            ),
        },
    ];

    if (loading) return <Loading />;
    return (
        <>
            <div
                className="global-class global-main-outer"
                style={{
                    paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
                    paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
                }}
            >
                {/* TopButtons */}
                <TopButtons />
                {/* Tabs */}
                <div className="global-main">
                    <Table
                        pagination={{
                            position: [i18n.language === "en" ? "bottomRight" : "bottomLeft"],
                        }}
                        scroll={{
                            x: 1500,
                        }}
                        bordered
                        columns={columns}
                        dataSource={filteredRequests}
                        style={{ direction: i18n.language === "en" ? "" : "rtl" }}
                    />
                </div>
            </div>
        </>
    )
}
