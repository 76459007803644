import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import userImg from "../../../../../assets/images/user.png";
import { useSelector } from "react-redux";

export default function ProfileImage() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <Avatar
          src={user?.profileImage === undefined ? userImg : user?.profileImage}
          alt={t("employees.profileImage")}
          style={{ width: 100, height: 100 }}
        />
        <h3>{t("employees.profileImage")}</h3>
      </div>
    </>
  );
}
