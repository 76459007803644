import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, TextField } from "@mui/material";
import logo from "../../../../../assets/images/logo.png";
import { useSelector } from "react-redux";

export default function PassportNumberAndImage() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <Avatar
            src={user?.passportImage === undefined ? logo : user?.passportImage}
            alt={t("employees.passportImage")}
            style={{ width: 100, height: 100 }}
          />
          <h3>{t("employees.passportImage")}</h3>
        </div>
        <TextField
          fullWidth
          value={user?.passportNumber}
          size="small"
          type="text"
          label={t("employees.passportNumber")}
          placeholder="e.g.  AM045465234"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
