import React, { useEffect } from "react";
import i18n from "../../../assets/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../redux/reduxSlice/globalSlice";
import { Table } from "antd";
import Loading from "../../../components/global/loading/Loading";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export default function Pending() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search, loading } = useSelector((state) => state.global);
  const { leaves } = useSelector((state) => state.leaves);

  const pendingAnnualLeaves = leaves?.filter((item) => item?.type === "annualLeave" && item?.supervisorStatus === "pending")

  const filteredLeaves = pendingAnnualLeaves.filter((item) => {
    const searchFieldLower = search.toLowerCase();
    return (
      (item.startDate &&
        item.startDate.toLowerCase().includes(searchFieldLower)) ||
      (item.endDate && item.endDate.toLowerCase().includes(searchFieldLower)) ||
      (item.englishName && item.englishName.toLowerCase().includes(searchFieldLower)) ||
      (item.email && item.email.toLowerCase().includes(searchFieldLower)) ||
      (item.phoneNumber && item.phoneNumber.toLowerCase().includes(searchFieldLower))
    );
  });
  useEffect(() => {
    dispatch(setSearch("")); // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: t("leaves.tableColOneTitle"),
      dataIndex: t("leaves.tableKeyOne"),
      sorter: (a, b) => a.englishName.localeCompare(b.englishName),
    },
    {
      title: t("leaves.tableColTwoTitle"),
      dataIndex: t("leaves.tableKeyTwo"),
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: t("leaves.tableColThreeTitle"),
      dataIndex: t("leaves.tableKeyThree"),
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("leaves.tableColFourTitle"),
      dataIndex: t("leaves.tableKeyFour"),
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
    },
    {
      title: t("leaves.tableColFiveTitle"),
      dataIndex: t("leaves.tableKeyFive"),
      sorter: (a, b) => a.endDate.localeCompare(b.endDate),
    },
    {
      title: t("leaves.tableColSixTitle"),
      dataIndex: t("leaves.tableKeySix"),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: t("leaves.tableColSevenTitle"),
      dataIndex: t("leaves.tableKeySeven"),
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: t("leaves.tableColEightTitle"),
      dataIndex: t("leaves.tableKeyEight"),
      sorter: (a, b) => a.hrStatus.localeCompare(b.hrStatus),
      render: (text) => {
        let statusClass = "";
        if (text === 'approved') statusClass = "status-approved";
        if (text === 'rejected') statusClass = "status-rejected";
        if (text === 'pending') statusClass = "status-pending";
        return <span className={statusClass}>{text}</span>;
      }
    },
    {
      title: t("leaves.tableColNineTitle"),
      dataIndex: t("leaves.tableKeyNine"),
      sorter: (a, b) => a.supervisorStatus.localeCompare(b.supervisorStatus),
      render: (text) => {
        let statusClass = "";
        if (text === 'approved') statusClass = "status-approved";
        if (text === 'rejected') statusClass = "status-rejected";
        if (text === 'pending') statusClass = "status-pending";
        return <span className={statusClass}>{text}</span>;
      }
    },
    {
      title: t("leaves.tableColTenTitle"),
      dataIndex: t("leaves.tableKeyTen"),
      render: (_, data) => (
        <>
          <Button
            onClick={() => navigate("/eitmamland/supervisor/viewAnnualLeave", { state: data })}
            sx={{
              margin: "0.5rem",
              background: "#4caf50",
              "&:hover": {
                background: "#388e3c",
              },
            }}
            variant="contained"
            size="small"
          >
            {t("leaves.viewButton")}
          </Button>
        </>
      ),
    },
  ];

  if (loading) return <Loading />;

  return (
    <>
      <Table
        pagination={{
          position: [i18n.language === "en" ? "bottomRight" : "bottomLeft"],
        }}
        scroll={{
          x: 1500,
        }}
        bordered
        columns={columns}
        dataSource={filteredLeaves}
        style={{ direction: i18n.language === "en" ? "" : "rtl" }}
      />
    </>
  );
}
