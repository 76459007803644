import { Button, TextField } from "@mui/material";
import React from "react";
import i18n from "../../../../assets/i18n/i18n";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setSearch } from "../../../../redux/reduxSlice/globalSlice";
import { useDispatch, useSelector } from "react-redux";

export default function TopButtons() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <div className="global-main">
        <Button
          onClick={
            i18n.language === "en"
              ? () => user?.role === "hr" ? navigate("/eitmamland/hr") : navigate("/eitmamland/supervisor")
              : () => user?.role === "hr" ? navigate("/eitmamland/hr/newEmployee") : navigate("/eitmamland/supervisor/newEmployee")
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("global.dashboardButton")}</>
          ) : (
            <>{t("employees.addButton")}</>
          )}
        </Button>
        <TextField
          value={search}
          onChange={(e) => dispatch(setSearch(e.target.value))}
          size="small"
          placeholder={
            i18n.language === "en"
              ? t("employees.searchEmployees")
              : t("employees.searchEmployees")
          }
          sx={{ margin: "0.5rem 0rem" }}
        />
        <Button
          onClick={
            i18n.language === "en"
              ? () => user?.role === "hr" ? navigate("/eitmamland/hr/newEmployee") : navigate("/eitmamland/supervisor/newEmployee")
              : () => user?.role === "hr" ? navigate("/eitmamland/hr") : navigate("/eitmamland/supervisor")
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("employees.addButton")}</>
          ) : (
            <>{t("global.dashboardButton")}</>
          )}
        </Button>
      </div>
      <div className="global-main" style={{
        width: "98%",
        display: "flex",
        justifyContent: i18n.language === "en" ? "left" : "right",
      }}>
        <h2>{t("sidebarHr.employees")}</h2>
      </div>
    </>
  );
}
