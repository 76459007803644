import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
    name: "global",
    initialState: {
        width: window.innerWidth,
        search: "",
        changePasswordModal: false,
        changePasswordForm: {},
        changePasswordFormErrors: {},
        showPassword: false,
        showConfirmPassword: false,
        loading: false,
        responseModal: { action: false, response: "" },
        responseModalForm: "",
    },
    reducers: {
        setWidth: (state, action) => {
            state.width = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setChangePasswordModal: (state, action) => {
            state.changePasswordModal = action.payload;
        },
        setChangePasswordForm: (state, action) => {
            state.changePasswordForm = action.payload;
        },
        setChangePasswordFormErrors: (state, action) => {
            state.changePasswordFormErrors = action.payload;
        },
        setShowPassword: (state, action) => {
            state.showPassword = action.payload;
        },
        setShowConfirmPassword: (state, action) => {
            state.showConfirmPassword = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setResponseModal: (state, action) => {
            state.responseModal = action.payload;
        },
        setResponseModalForm: (state, action) => {
            state.responseModalForm = action.payload;
        },
        clearGlobalStates: (state) => {
            state.showConfirmPassword = false;
            state.showPassword = false;
            state.changePasswordFormErrors = {};
            state.changePasswordForm = {};
            state.changePasswordModal = false;
            state.loading = false;
            state.responseModal = { action: false, response: "" };
            state.responseModalForm = "";

        }
    },
});

export const { setWidth, setSearch, setChangePasswordModal, setChangePasswordForm, setLoading
    , setChangePasswordFormErrors, setShowPassword, setShowConfirmPassword, clearGlobalStates,
    setResponseModal, setResponseModalForm
} = globalSlice.actions;

export default globalSlice.reducer;
