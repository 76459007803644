import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../assets/styles/PrivacyPolicy.css"
import Header from "../../../components/global/header/Header";
import CarouselComponent from "../../../components/global/carousel/CarouselComponent";
import WhatsAppButton from "../../../components/global/whatsappButton/WhatsappButton";
import Footer from "../../../components/global/footer/Footer";
import i18n from '../../../assets/i18n/i18n';
import { scrollToTop } from "../../../utils/scrollToTop";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    useEffect(() => {
        scrollToTop();   // eslint-disable-next-line
    }, [])
    return (
        <>
            <Header />
            <CarouselComponent />
            <div className="container">
                <header>
                    <h1 style={{
                        textAlign: i18n.language === "en" ? "left" : "right",
                    }} className="header">{t("privacyPolicy.title")}</h1>
                </header>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="introduction">
                    <h2>{t("privacyPolicy.sections.introduction.title")}</h2>
                    <p>{t("privacyPolicy.sections.introduction.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="consent">
                    <h2>{t("privacyPolicy.sections.consent.title")}</h2>
                    <p>{t("privacyPolicy.sections.consent.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="information">
                    <h2>{t("privacyPolicy.sections.information.title")}</h2>
                    <p>{t("privacyPolicy.sections.information.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="usage">
                    <h2>{t("privacyPolicy.sections.usage.title")}</h2>
                    <p>{t("privacyPolicy.sections.usage.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="logs">
                    <h2>{t("privacyPolicy.sections.logs.title")}</h2>
                    <p>{t("privacyPolicy.sections.logs.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="cookies">
                    <h2>{t("privacyPolicy.sections.cookies.title")}</h2>
                    <p>{t("privacyPolicy.sections.cookies.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="third-party">
                    <h2>{t("privacyPolicy.sections.thirdParty.title")}</h2>
                    <p>{t("privacyPolicy.sections.thirdParty.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="ccpa-gdpr">
                    <h2>{t("privacyPolicy.sections.ccpaGdpr.title")}</h2>
                    <p>{t("privacyPolicy.sections.ccpaGdpr.content")}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} id="children">
                    <h2>{t("privacyPolicy.sections.children.title")}</h2>
                    <p>{t("privacyPolicy.sections.children.content")}</p>
                </section>

                <footer style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }} className="contact-info">
                    <h2>{t("privacyPolicy.contact.title")}</h2>
                    <p>{t("privacyPolicy.contact.content", { email: 'contact@tasheel247.ae' })}</p>
                </footer>
            </div>
            <Footer />
            <WhatsAppButton />
        </>
    );
};

export default PrivacyPolicy;
