import React from "react";
import Carousel from "react-material-ui-carousel";
import image1 from "../../../assets/images/image1.png";
import image2 from "../../../assets/images/image2.png";
import "../../../assets/styles/LandingPage.css";

export default function CarouselComponent() {
  let items = [
    {
      slide: (
        <>
          <div
            className="image"
            style={{
              backgroundImage: `url(${image1})`,
              height: 600,
            }}
          ></div>
        </>
      ),
    },
    {
      slide: (
        <>
          <div
            className="image"
            style={{
              backgroundImage: `url(${image2})`,
              backgroundPosition: "center",
              height: 600,
            }}
          ></div>
        </>
      ),
    },
  ];
  return (
    <>
      <Carousel
        // height={700}
        autoPlay={true}
        animation="slide"
        indicators={false}
        duration={1000}
      >
        {items.map((item) => {
          return <>{item?.slide}</>;
        })}
      </Carousel>
    </>
  );
}
