import { db } from "../../../firebase/firebase";
import { message } from "antd";
import { deleteDoc, doc } from "firebase/firestore";
import { setLoading } from "../../../redux/reduxSlice/globalSlice"
import { deleteFileByURL } from "../../../utils/deleteFileByURL";
import { fetchLeaveData, getLeavesByUserId } from "../getLeaves/getLeavesFunctions";
import { setUserLeaves } from "../../../redux/reduxSlice/leavesSlice";

export const deleteLeave = async (data, t, dispatch) => {
    try {
        dispatch(setLoading(true))
        const leaveDoc = doc(db, "leaves", data?.leaveId);
        if (data?.attachments?.length > 0) {
            await Promise.all(data?.attachments?.map(async (item) => await deleteFileByURL(item)));
        }
        await deleteDoc(leaveDoc);
        const userLeaves = await getLeavesByUserId(data?.userId)
        dispatch(setUserLeaves(userLeaves))
        await fetchLeaveData(dispatch)
        message.success(t("leaves.deleteLeaveSuccess"))
    } catch (error) {
        dispatch(setLoading(false))
        message.error(t("leaves.deleteLeaveError"))
    }
}