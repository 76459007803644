import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

export default function AnnualLeaves() {
  const { editEmployeeForm } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="remainingAnnualLeaves"
          name="remainingAnnualLeaves"
          autoComplete="remainingAnnualLeaves"
          autoFocus
          label={t("employees.remainingAnnualLeaves")}
          value={editEmployeeForm?.remainingAnnualLeaves}
          disabled={true}
          size="small"
          type="number"
        />
        <TextField
          fullWidth
          id="remainingSickLeaves"
          name="remainingSickLeaves"
          autoComplete="remainingSickLeaves"
          autoFocus
          value={editEmployeeForm?.remainingSickLeaves}
          disabled={true}
          size="small"
          type="number"
          label={t("employees.remainingSickLeaves")}
          placeholder="e.g.10"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
