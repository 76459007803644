import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { Avatar, TextField } from "@mui/material";
import { logo } from "../../../../../assets/constants/global";
import { AiOutlineCamera } from "react-icons/ai";

export default function WorkPermitNumberAndImage() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <div className="image-main-div">
            <div className="image-inner-div">
              <Avatar
                src={editEmployeeForm?.workPermitImageNew !== undefined ?
                  URL.createObjectURL(editEmployeeForm?.workPermitImageNew) :
                  editEmployeeForm?.workPermitImage
                    ? editEmployeeForm?.workPermitImage
                    : logo
                }
                alt={t("employees.workPermitImage")}
                style={{ width: 100, height: 100 }}
              />
              <label style={{ cursor: "pointer" }}
                htmlFor={editEmployeeForm?.workPermitImage !== undefined ? "workPermitImage" : "workPermitImageNew"}>
                <div style={{ position: "relative" }}>
                  <span>
                    <AiOutlineCamera size={28} className="image-icon" />
                  </span>
                </div>

                <input
                  id={editEmployeeForm?.workPermitImage !== undefined ? "workPermitImage" : "workPermitImageNew"}
                  name={editEmployeeForm?.workPermitImage !== undefined ? "workPermitImage" : "workPermitImageNew"}
                  autoComplete={editEmployeeForm?.workPermitImage !== undefined ? "workPermitImage" : "workPermitImageNew"}
                  autoFocus
                  type="file"
                  hidden
                  size="small"
                  placeholder={t("employees.workPermitImage")}
                  onChange={(e) =>
                    handleEditEmployeeInputChange(
                      e,
                      editEmployeeForm,
                      dispatch, t, employees
                    )
                  }
                />
                <br />
              </label>
            </div>
          </div>
          <h3>{t("employees.workPermitImage")}</h3>
        </div>
        <TextField
          fullWidth
          id="workPermitNumber"
          name="workPermitNumber"
          autoComplete="workPermitNumber"
          autoFocus
          error={!!editEmployeeErrors?.workPermitNumber}
          helperText={editEmployeeErrors?.workPermitNumber}
          value={editEmployeeForm?.workPermitNumber}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="number"
          label={t("employees.workPermitNumber")}
          placeholder="e.g.  AM045465234"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
