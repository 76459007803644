import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleSubmit } from "../../../../libs/employees/addNewEmployee/addNewEmployeeFunctions";

export default function SubmitButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(
    (state) => state.user
  );
  const { newEmployeeForm, newEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  return (
    <>
      <div className="add-employees-btn">
        <Button
          onClick={(e) =>
            handleSubmit(
              e, user,
              newEmployeeForm,
              newEmployeeErrors,
              dispatch,
              navigate,
              t,
              employees
            )
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {t("employees.addNewEmployeeButton")}
        </Button>
      </div >
    </>
  );
}
