import { TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { handleNewEmployeeInputChange } from "../../../../libs/employees/addNewEmployee/addNewEmployeeFunctions";

export default function NameAndEmail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newEmployeeForm, newEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="englishName"
          name="englishName"
          autoComplete="englishName"
          autoFocus
          error={!!newEmployeeErrors?.englishName}
          helperText={newEmployeeErrors?.englishName}
          value={newEmployeeForm?.englishName}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type="text"
          label={t("employees.englishName")}
          placeholder="e.g. John Doe"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!newEmployeeErrors?.email}
          helperText={newEmployeeErrors?.email}
          value={newEmployeeForm?.email}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type="email"
          label={t("employees.email")}
          placeholder="e.g. email@eitmamtasheel.ae"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
