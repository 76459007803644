import React from "react";
import "../../../assets/styles/LandingFooter.css";
import logo from "../../../assets/images/logo.jpg";
import { FaPhoneAlt, FaInfoCircle, FaHome, FaLanguage } from "react-icons/fa";
import { MdEmail, MdContacts, MdLogin } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { RiCustomerService2Fill } from "react-icons/ri";
import { handleLanguageChange } from "../../../utils/changeLanguage";
import { useTranslation } from "react-i18next";
import { FaLinkedinIn, FaWhatsapp, FaFacebookF } from "react-icons/fa";
import { Copyright } from "../../../utils/copyright";

const size = 20;
export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="landing-footer-class">
        <div className="landing-footer-inner">
          <div className="img" style={{ margin: "1rem 0rem" }}>
            <img src={logo} alt="eitmam-land" />
          </div>
          <div className="social-icons">
            <FaFacebookF size={30} style={{ margin: "0rem 2rem" }} />
            <a href="https://wa.me/+97125833982" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp size={30} style={{ color: "white", margin: "0rem 2rem" }} />
            </a>
            <a href="https://www.linkedin.com/in/eitmam-land-92a588319/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn size={30} style={{ color: "white", margin: "0rem 2rem" }} />
            </a>
          </div>
        </div>
        <div className="landing-footer-inner">
          <div className="landing-footer-content">
            <span>
              <FaPhoneAlt size={size} />
            </span>
            <span>+971 2 583 3982</span>
          </div>
          <div className="landing-footer-content">
            <span>
              <MdEmail size={size} />
            </span>
            <span>
              <a
                href="mailto:info@eitmamtasheel.ae"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@eitmamtasheel.ae
              </a>
            </span>
          </div>
          <div className="landing-footer-content">
            <span>
              <FaLocationDot size={size} />
            </span>
            <span>
              <a
                href="https://www.google.com/maps/search/?api=1&query=Bawabet+Al+Sharq+Mall"
                target="_blank"
                rel="noopener noreferrer"
              >
                1st Floor - Bawabet Al Sharq Mall...
              </a>
            </span>
          </div>
        </div>
        <div className="landing-footer-inner">
          <div
            className="landing-footer-content"
            onClick={() => navigate("/about")}
          >
            <span>
              <FaInfoCircle size={size} />
            </span>
            <span>{t("header.about")}</span>
          </div>
          <div
            className="landing-footer-content"
            onClick={() => navigate("/services")}
          >
            <span>
              <RiCustomerService2Fill size={size} />
            </span>
            <span>{t("header.services")}</span>
          </div>
          <div
            className="landing-footer-content"
            onClick={() => navigate("/contact")}
          >
            <span>
              <MdContacts size={size} />
            </span>
            <span>{t("header.contact")}</span>
          </div>
        </div>
        <div className="landing-footer-inner">
          <div
            className="landing-footer-content"
            onClick={() => navigate("/")}
          >
            <span>
              <FaHome size={size} />
            </span>
            <span>{t("header.home")}</span>
          </div>
          <div
            className="landing-footer-content"
            onClick={handleLanguageChange}
          >
            <span>
              <FaLanguage size={size} />
            </span>
            <span>{t("header.language")}</span>
          </div>
          <div
            className="landing-footer-content"
            onClick={() => navigate("/login")}
          >
            <span>
              <MdLogin size={size} />
            </span>
            <span>{t("header.login")}</span>
          </div>
        </div>
      </div>
      <div className="landing-footer-class"
        style={{
          justifyContent: "space-evenly"
        }}
      >
        <Copyright sx={{ mt: 1, mb: 1 }} t={t} color="white" />
        <div
          style={{
            display: "flex",
            color: "white"
          }}
        >
          <Link to={"/privacyPolicy"} style={{ color: "white" }}>
            {t("header.privacyPolicy")}
          </Link>
          <span style={{ color: "white", margin: "0rem 1rem" }}>|</span>
          <Link to={"/termsOfUse"} style={{ color: "white" }}>
            {t("header.termsOfUse")}
          </Link>
        </div>
      </div >
    </>
  );
}
