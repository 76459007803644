import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";

export default function AddressAndNationality() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="address"
          name="address"
          autoComplete="address"
          autoFocus
          error={!!editEmployeeErrors?.address}
          helperText={editEmployeeErrors?.address}
          value={editEmployeeForm?.address}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="text"
          label={t("employees.address")}
          placeholder="e.g.  baniyas,abu dhabi"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="nationality"
          name="nationality"
          autoComplete="nationality"
          autoFocus
          error={!!editEmployeeErrors?.nationality}
          helperText={editEmployeeErrors?.nationality}
          value={editEmployeeForm?.nationality}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="text"
          label={t("employees.nationality")}
          placeholder="e.g.  emirati"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
