import { message } from "antd";
export const onSubmit = async (event, t) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("access_key", "ddbed66a-61ac-4537-b03e-e597c58ae8e8");
    const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
    });
    const data = await response.json();
    if (data.success) {
        event.target.reset();
        message.success(t("message.success"))
    } else {
        message.error(t("message.error"))
    }
};