import { createSlice } from "@reduxjs/toolkit";
import dateUtils from "../../utils/dateUtils";

const leavesSlice = createSlice({
    name: "leaves",
    initialState: {
        newLeaveForm: {
            startDate: dateUtils.getCurrentDate(),
            endDate: dateUtils.getCurrentDate(),
            description: "",
            totalLeaveDays: 0,
            attachments: [],
        },
        newLeaveErrors: {
            startDate: "",
            endDate: "",
            description: "",
            totalLeaveDays: "",
            attachments: "",
        },
        editLeaveForm: {},
        editLeaveErrors: {},
        leaves: [],
        monthlyLeaves: [],
        userLeaves: [],
    },
    reducers: {
        setNewLeaveForm: (state, action) => {
            state.newLeaveForm = action.payload;
        },
        setNewLeaveErrors: (state, action) => {
            state.newLeaveErrors = action.payload;
        },
        setEditLeaveForm: (state, action) => {
            state.editLeaveForm = action.payload;
        },
        setEditLeaveErrors: (state, action) => {
            state.editLeaveErrors = action.payload;
        },
        setLeaves: (state, action) => {
            state.leaves = action.payload;
        },
        setMonthlyLeaves: (state, action) => {
            state.monthlyLeaves = action.payload;
        },
        setUserLeaves: (state, action) => {
            state.userLeaves = action.payload;
        },
        clearNewLeaveForm: (state) => {
            state.newLeaveForm = {
                startDate: dateUtils.getCurrentDate(),
                endDate: dateUtils.getCurrentDate(),
                description: "",
                totalLeaveDays: 0,
                attachments: [],
            };
            state.newLeaveErrors = {
                startDate: "",
                endDate: "",
                description: "",
                totalLeaveDays: "",
                attachments: "",
            };
        },
        clearEditLeaveForm: (state) => {
            state.editLeaveForm = {};
            state.editLeaveErrors = {};
        },
    },
});

export const { setNewLeaveForm, setUserLeaves, setNewLeaveErrors,
    setEditLeaveForm, setEditLeaveErrors, setLeaves, setMonthlyLeaves,
    clearNewLeaveForm, clearEditLeaveForm } = leavesSlice.actions;

export default leavesSlice.reducer;
