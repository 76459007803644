import React, { useEffect } from 'react'
import i18n from "../../../assets/i18n/i18n";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/Employees.css";
import Loading from "../../../components/global/loading/Loading";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, InputAdornment, TextField } from "@mui/material";
import dateUtils from '../../../utils/dateUtils';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaRegFilePdf } from "react-icons/fa";
import { handleNewLeaveInputChange, handleRemoveAttachments, handleSubmit } from '../../../libs/leaves/requestNewLeave/requestNewLeaveFunctions';
import { setNewLeaveForm } from '../../../redux/reduxSlice/leavesSlice';

export default function RequestNewAnnualLeaveHr() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { width, loading } = useSelector((state) => state.global);
  const { newLeaveForm, newLeaveErrors } = useSelector((state) => state.leaves);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    const totalLeaveDays = dateUtils?.calculateTotalLeaveDays(newLeaveForm);
    if (newLeaveForm.totalLeaveDays !== totalLeaveDays) {
      dispatch(setNewLeaveForm({ ...newLeaveForm, totalLeaveDays }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLeaveForm.startDate, newLeaveForm.endDate]);
  if (loading) return <Loading />;
  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <div className="global-main">
          <Button
            onClick={() => navigate("/eitmamland/hr")}
            sx={{
              margin: "0.5rem 0rem",
              background: "#d4df98",
              "&:hover": {
                background: "#a4b964",
              },
            }}
            variant="contained"
          >

            {t("global.dashboardButton")}

          </Button>
        </div>
        {/* heading */}
        <div className="global-main">
          <h2
            className="add-employees-heading"
            style={{
              justifyContent:
                i18n.language !== "en" ? "flex-end" : "flex-start",
            }}
          >
            {t("global.addNewLeave")}
          </h2>
        </div>
        {/* start and end date */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="startDate"
            name="startDate"
            autoComplete="startDate"
            autoFocus
            error={!!newLeaveErrors?.startDate}
            helperText={newLeaveErrors?.startDate}
            value={newLeaveForm?.startDate}
            onChange={(e) =>
              handleNewLeaveInputChange(
                e,
                newLeaveForm,
                dispatch,
                t
              )
            }
            defaultValue={dateUtils.getCurrentDate()}
            size="small"
            type="date"
            label={t("leaves.startDate")}
            placeholder="e.g. 27/05/2024"
            sx={{ margin: "0.5rem" }}
          />
          <TextField
            required
            fullWidth
            id="endDate"
            name="endDate"
            autoComplete="endDate"
            autoFocus
            error={!!newLeaveErrors?.endDate}
            helperText={newLeaveErrors?.endDate}
            value={newLeaveForm?.endDate}
            onChange={(e) =>
              handleNewLeaveInputChange(
                e,
                newLeaveForm,
                dispatch,
                t
              )
            }
            defaultValue={dateUtils.getCurrentDate()}
            size="small"
            type="date"
            label={t("leaves.endDate")}
            placeholder="e.g. 27/05/2024"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        {/* total Leave Days */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="totalLeaveDays"
            name="totalLeaveDays"
            autoComplete="totalLeaveDays"
            autoFocus
            value={newLeaveForm?.totalLeaveDays}
            size="small"
            type="text"
            inputProps={{ readOnly: true }}
            label={t("leaves.totalLeaveDays")}
            placeholder="e.g. 2"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        {/* description and attachements */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="description"
            name="description"
            autoComplete="description"
            autoFocus
            error={!!newLeaveErrors?.description}
            helperText={newLeaveErrors?.description}
            value={newLeaveForm?.description}
            onChange={(e) =>
              handleNewLeaveInputChange(
                e,
                newLeaveForm,
                dispatch,
                t
              )
            }
            size="small"
            type="text"
            multiline
            rows={6}
            label={t("leaves.description")}
            placeholder="e.g. leave details"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        <div className="global-main">
          <TextField
            type="file"
            fullWidth
            id="attachments"
            name="attachments"
            autoComplete="attachments"
            autoFocus
            label={t("leaves.attachments")}
            placeholder="e.g. Attachments"
            sx={{ margin: "0.5rem" }}
            onChange={(e) =>
              handleNewLeaveInputChange(
                e,
                newLeaveForm,
                dispatch,
                t
              )
            }
            inputProps={{ accept: 'image/*,.pdf' }}
          />
        </div>
        {/* attachments view */}
        {newLeaveForm?.attachments?.length > 0 ?
          <>
            {newLeaveForm?.attachments?.map((item) => {
              return (
                <>
                  <div className="global-main">
                    <TextField
                      fullWidth
                      sx={{ margin: "0.5rem" }}
                      autoFocus
                      value={item?.name}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            {item?.type === "application/pdf" ? (
                              <Avatar sx={{ background: "#FAF9F6" }}>
                                <FaRegFilePdf size={25} color="red" />
                              </Avatar>
                            ) : (
                              <Avatar
                                src={URL.createObjectURL(item)}
                                alt="Attachment"
                              />
                            )}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IoMdCloseCircleOutline size={25} color='red' cursor="pointer"
                              onClick={() =>
                                handleRemoveAttachments(
                                  dispatch,
                                  newLeaveForm,
                                  item,
                                )
                              } />
                          </InputAdornment>
                        ),
                      }} />
                  </div>
                </>

              )
            })}
          </> : ""}

        {/* SubmitButton */}
        <div className="add-employees-btn">
          <Button
            onClick={(e) =>
              handleSubmit(
                e,
                newLeaveForm,
                newLeaveErrors,
                dispatch,
                navigate,
                t,
                user,
                "annualLeave"
              )
            }
            sx={{
              margin: "0.5rem 0rem",
              background: "#d4df98",
              "&:hover": {
                background: "#a4b964",
              },
            }}
            variant="contained"
          >
            {t("global.submit")}
          </Button>
        </div>
      </div >
    </>
  )
}

