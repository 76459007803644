import React from 'react'
import { onSubmit } from '../../../utils/contactusForm'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from "@mui/material";
import i18n from "../../../assets/i18n/i18n";
import "../../../assets/styles/LandingPage.css"
import "../../../assets/styles/Services.css"

export default function ContactusForm() {
    const { t } = useTranslation()
    return (
        <>
            <div className="container">
                <h1 style={{ textAlign: "center" }}>{t("header.contact")}</h1>
                <div className="contact-main">
                    <div style={{ width: "100%", }}>
                        <h2 style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: i18n.language === "en" ? "flex-start" : "flex-end",
                        }}>{t("message.title")}</h2>
                        <form onSubmit={(e) => onSubmit(e, t)}>
                            <input type="hidden" name="subject" value="New Client from Eitmamland Website" />
                            <input type="hidden" name="from_name" value="Eitmamland Website" />
                            <input type="hidden" name="access_key" value="ddbed66a-61ac-4537-b03e-e597c58ae8e8" />
                            <TextField
                                required
                                fullWidth
                                id="name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                size="small"
                                type="text"
                                label={t("message.name")}
                                placeholder="e.g. Ali Abdul"
                                sx={{ margin: "0.5rem" }}
                            />
                            <TextField
                                required
                                fullWidth
                                id="email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                size="small"
                                type="email"
                                label={t("message.email")}
                                placeholder="e.g. email@eitmamtasheel.ae"
                                sx={{ margin: "0.5rem" }}
                            />
                            <TextField
                                required
                                fullWidth
                                id="message"
                                name="message"
                                autoComplete="message"
                                autoFocus
                                multiline
                                rows={6}
                                size="small"
                                type="text"
                                label={t("message.message")}
                                placeholder="e.g. type message"
                                sx={{ margin: "0.5rem" }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                sx={{
                                    margin: "0.5rem",
                                    background: "#d4df98",
                                    "&:hover": {
                                        background: "#a4b964",
                                    },
                                }}
                                variant="contained"
                            >
                                {t("global.submit")}
                            </Button>
                        </form>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3635.9901579152456!2d54.6196209!3d24.311978399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e371346266057%3A0xac0b77d741bf1a1e!2sBawabat%20Al%20Sharq%20Mall!5e0!3m2!1sen!2sae!4v1720770080130!5m2!1sen!2sae"
                        className='map'
                        title="eitmamland" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    )
}
