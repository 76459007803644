import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { TextField } from "@mui/material";

export default function WorkPermitPersonalNumber() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="workPermitPersonalNumber"
          name="workPermitPersonalNumber"
          autoComplete="workPermitPersonalNumber"
          autoFocus
          error={!!editEmployeeErrors?.workPermitPersonalNumber}
          helperText={editEmployeeErrors?.workPermitPersonalNumber}
          value={editEmployeeForm?.workPermitPersonalNumber}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="number"
          label={t("employees.workPermitPersonalNumber")}
          placeholder="e.g.  AM045465234"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
