import React, { useEffect } from "react";
import Widgets from "../../../components/global/widgets/Widgets";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../../../assets/styles/HrDashboard.css";
import { FaUsers } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../../assets/i18n/i18n";
import { useTranslation } from "react-i18next";
import { FaCodePullRequest } from "react-icons/fa6";
import { MdSick, MdFlight } from "react-icons/md";
import { Tabs } from "antd";
import NewAnnualLeave from "../../../components/supervisor/supervisorDashboard/NewAnnualLeaves";
import NewRequests from "../../../components/supervisor/supervisorDashboard/NewRequests";
import NewSickLeave from "../../../components/supervisor/supervisorDashboard/NewSickLeaves";
import { fetchData } from "../../../libs/employees/employeesFunctions";
import { fetchLeaveData, fetchLeaveOfMonthData } from "../../../libs/leaves/getLeaves/getLeavesFunctions";
import { fetchRequestData, fetchRequestOfMonthData } from "../../../libs/requests/getRequests/getRequestFunctions";
import AnnualLeavesBarChartSupervisor from "../../../components/supervisor/chartSupervisor/AnnualLeavesBarChartSupervisor";
import RequestsBarChartSupervisor from "../../../components/supervisor/chartSupervisor/RequestsBarChartSupervisor";
import SickLeavesBarChartSupervisor from "../../../components/supervisor/chartSupervisor/SickLeavesBarChartSupervisor";

export default function SupervisorDashboard() {
  const dispatch = useDispatch()
  const size = 25;
  const { t } = useTranslation();
  const hour = new Date().getHours();
  let greeting;

  if (hour < 12) {
    greeting =
      i18n.language === "en"
        ? t("dashboardHr.greetingMorning")
        : t("dashboardHr.greetingMorning");
  } else {
    greeting =
      i18n.language === "en"
        ? t("dashboardHr.greetingEvening")
        : t("dashboardHr.greetingEvening");
  }
  const navigate = useNavigate();
  const { width } = useSelector((state) => state.global);
  const { employees } = useSelector((state) => state.employees);
  const { monthlyLeaves, leaves } = useSelector((state) => state.leaves);
  const { monthlyRequests, requests } = useSelector((state) => state.requests);
  const sickLeavesData = leaves?.filter((item) => item?.type === "sickLeave")
  const annualLeavesData = leaves?.filter((item) => item?.type === "annualLeave")
  const monthlySickLeaves = monthlyLeaves?.filter((item) => item?.type === "sickLeave")
  const monthlyAnnualLeaves = monthlyLeaves?.filter((item) => item?.type === "annualLeave")
  useEffect(() => {
    fetchData(dispatch)
    fetchLeaveData(dispatch)
    fetchRequestData(dispatch)
    fetchLeaveOfMonthData(dispatch)
    fetchRequestOfMonthData(dispatch)
    // eslint-disable-next-line
  }, []);
  const data = [
    {
      title:
        i18n.language === "en"
          ? t("dashboardHr.employees")
          : t("dashboardHr.requests"),
      subtitle:
        i18n.language === "en"
          ? t("dashboardHr.totalEmployees")
          : t("dashboardHr.requests"),
      value: i18n.language === "en" ? employees?.length : monthlyRequests?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon:
        i18n.language === "en" ? (
          <FaUsers size={size} />
        ) : (
          <FaCodePullRequest size={size} />
        ),
      onClick:
        i18n.language === "en"
          ? () => navigate("/eitmamland/supervisor/employees")
          : () => navigate("/eitmamland/supervisor/monthlyRequests"),
    },
    {
      title: i18n.language === "en"
        ? t("dashboardHr.sickLeave") : t("dashboardHr.annualLeave"),
      subtitle: i18n.language === "en"
        ? t("dashboardHr.monthlySickLeave") : t("dashboardHr.monthlyAnnualLeave"),
      value: i18n.language === "en"
        ? monthlySickLeaves?.length : monthlyAnnualLeaves?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon: i18n.language === "en"
        ? <MdSick size={size} /> : <MdFlight size={size} />,
      onClick: i18n.language === "en"
        ? () => navigate("/eitmamland/supervisor/monthlySickLeave") : () => navigate("/eitmamland/supervisor/monthlyAnnualLeave"),
    },
    {
      title: i18n.language === "en"
        ? t("dashboardHr.annualLeave") : t("dashboardHr.sickLeave"),
      subtitle: i18n.language === "en"
        ? t("dashboardHr.monthlyAnnualLeave") : t("dashboardHr.monthlySickLeave"),
      value: i18n.language === "en"
        ? monthlyAnnualLeaves?.length : monthlySickLeaves?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon: i18n.language === "en"
        ? <MdFlight size={size} /> : <MdSick size={size} />,

      onClick: i18n.language === "en"
        ? () => navigate("/eitmamland/supervisor/monthlyAnnualLeave") : () => navigate("/eitmamland/supervisor/monthlySickLeave"),
    },
    {
      title:
        i18n.language === "en"
          ? t("dashboardHr.requests")
          : t("dashboardHr.employees"),
      subtitle:
        i18n.language === "en"
          ? t("dashboardHr.monthlyRequests")
          : t("dashboardHr.totalEmployees"),
      value: i18n.language === "en" ? monthlyRequests?.length : employees?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon:
        i18n.language === "en" ? (
          <FaCodePullRequest size={size} />
        ) : (
          <FaUsers size={size} />
        ),
      onClick:
        i18n.language === "en"
          ? () => navigate("/eitmamland/supervisor/monthlyRequests")
          : () => navigate("/eitmamland/supervisor/employees"),
    },
  ];

  const items = [
    {
      key: "1",
      label: t("global.newAnnualLeave"),
      children:
        <>
          <h2>Monthly Annual Leaves and Total Leave Days</h2>
          <AnnualLeavesBarChartSupervisor annualLeavesData={annualLeavesData} />
          <NewAnnualLeave />
        </>,
    },
    {
      key: "2",
      label: t("global.newSickLeave"),
      children:
        <>
          <h2>Monthly Sick Leaves and Total Leave Days</h2>
          <SickLeavesBarChartSupervisor sickLeavesData={sickLeavesData} />
          <NewSickLeave />
        </>,
    },
    {
      key: "3",
      label: t("global.newRequests"),
      children:
        <>
          <h2>Monthly Requests</h2>
          <RequestsBarChartSupervisor requestsData={requests} />
          <NewRequests />
        </>,
    },
  ];
  return (
    <>
      <div className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "14.5%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "14.5%" : 0,
        }}>
        <h2
          className="hr-dashboard-main"
          style={{
            justifyContent: i18n.language !== "en" ? "flex-end" : "flex-start",
          }}
        >
          {i18n.language === "en" ? (
            <>
              <span className="time">{greeting}</span>{" "}
              <span>{t("dashboardHr.welcomeText")}</span>{" "}
            </>
          ) : (
            <>
              <span>{t("dashboardHr.welcomeText")}</span>{" "}
              <span className="time">{greeting}</span>
            </>
          )}
        </h2>
        <div
          className="hr-dashboard"
        >
          {data.map((item) => (
            <div key={item.id} style={{ margin: "0.5rem", cursor: "pointer" }}>
              <Widgets item={item} />
            </div>
          ))}
          <div className="global-main" style={{ margin: "1rem 0rem" }}>
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="large"
              style={{ width: "100%" }}
              items={items}
            />
          </div>
        </div>
      </div>
    </>
  );
}
