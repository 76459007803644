import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import dateUtils from "../../../../../utils/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";

export default function PassportIssueAndExpiry() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="passportIssue"
          name="passportIssue"
          autoComplete="passportIssue"
          autoFocus
          error={!!editEmployeeErrors?.passportIssue}
          helperText={editEmployeeErrors?.passportIssue}
          value={editEmployeeForm?.passportIssue === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.passportIssue}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.passportIssue")}
          placeholder="e.g.  18/02/2020"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="passportExpiry"
          name="passportExpiry"
          autoComplete="passportExpiry"
          autoFocus
          error={!!editEmployeeErrors?.passportExpiry}
          helperText={editEmployeeErrors?.passportExpiry}
          value={editEmployeeForm?.passportExpiry === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.passportExpiry}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.passportExpiry")}
          placeholder="e.g. 18/02/2024"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
