import React from "react";
import { useTranslation } from "react-i18next";
import dateUtils from "../../../../../utils/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { TextField } from "@mui/material";

export default function WorkPermitIssueAndExpiry() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="workPermitIssue"
          name="workPermitIssue"
          autoComplete="workPermitIssue"
          autoFocus
          error={!!editEmployeeErrors?.workPermitIssue}
          helperText={editEmployeeErrors?.workPermitIssue}
          value={editEmployeeForm?.workPermitIssue === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.workPermitIssue}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.workPermitIssue")}
          placeholder="e.g.  18/02/2020"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="workPermitExpiry"
          name="workPermitExpiry"
          autoComplete="workPermitExpiry"
          autoFocus
          error={!!editEmployeeErrors?.workPermitExpiry}
          helperText={editEmployeeErrors?.workPermitExpiry}
          value={editEmployeeForm?.workPermitExpiry === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.workPermitExpiry}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.workPermitExpiry")}
          placeholder="e.g. 18/02/2024"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
