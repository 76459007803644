import { createSlice } from "@reduxjs/toolkit";
import dateUtils from "../../utils/dateUtils";

const employeesSlice = createSlice({
    name: "employees",
    initialState: {
        newEmployeeForm: {
            englishName: "",
            email: "",
            phoneNumber: "",
            dateOfJoining: dateUtils.getCurrentDate(),
            remainingAnnualLeaves: "",
            remainingSickLeaves: "",
            password: "",
            confirmPassword: "",
            gender: "",
            role: "",
        },
        newEmployeeErrors: {
            englishName: "",
            email: "",
            phoneNumber: "",
            dateOfJoining: "",
            remainingAnnualLeaves: "",
            remainingSickLeaves: "",
            password: "",
            confirmPassword: "",
            gender: "",
            role: "",
        },
        editEmployeeForm: {},
        editEmployeeErrors: {},
        employees: [],
    },
    reducers: {
        setNewEmployeeForm: (state, action) => {
            state.newEmployeeForm = action.payload;
        },
        setNewEmployeeErrors: (state, action) => {
            state.newEmployeeErrors = action.payload;
        },
        setEditEmployeeForm: (state, action) => {
            state.editEmployeeForm = action.payload;
        },
        setEditEmployeeErrors: (state, action) => {
            state.editEmployeeErrors = action.payload;
        },
        setEmployees: (state, action) => {
            state.employees = action.payload;
        },
        clearNewEmployeeForm: (state) => {
            state.newEmployeeForm = {
                englishName: "",
                email: "",
                phoneNumber: "",
                dateOfJoining: dateUtils.getCurrentDate(),
                remainingAnnualLeaves: "",
                remainingSickLeaves: "",
                password: "",
                confirmPassword: "",
                gender: "",
                role: "",
            };
            state.newEmployeeErrors = {
                englishName: "",
                email: "",
                phoneNumber: "",
                dateOfJoining: "",
                remainingAnnualLeaves: "",
                remainingSickLeaves: "",
                password: "",
                confirmPassword: "",
                gender: "",
                role: "",
            };
        },
        clearEditEmployeeForm: (state) => {
            state.editEmployeeForm = {};
            state.editEmployeeErrors = {};
        },
    },
});

export const { setNewEmployeeForm, setNewEmployeeErrors, setEditEmployeeForm, setEditEmployeeErrors,
    setEmployees, clearNewEmployeeForm, clearEditEmployeeForm } = employeesSlice.actions;

export default employeesSlice.reducer;
