import React from "react";
import { useTranslation } from "react-i18next";
import about from "../../../assets/images/about.png"
import about1 from "../../../assets/images/about1.jpg"
import mohre from "../../../assets/images/mohre.jfif"
import federalAuthority from "../../../assets/images/federalAuthority.webp"
import shamTravels from "../../../assets/images/shamTravels.png"
import "../../../assets/styles/LandingPage.css"
import "../../../assets/styles/Services.css"
import i18n from "../../../assets/i18n/i18n";

export default function AboutUs() {
    const { t } = useTranslation()
    return (
        <>
            <div className="container">
                <h1 style={{ textAlign: "center" }}>{t("header.about")}</h1>
                <div className="about-main" >
                    <p>
                        <h2 style={{
                            textAlign: i18n.language === "en" ? "left" : "right",

                        }}>{t("about.title")}</h2>
                        <br />
                        {t("about.whoWeAre")}
                        <br />
                        <br />
                        {t("about.ourMision")}
                    </p>
                    <img src={about} alt="eitmam land" />
                </div>
                <div className="about-main1">
                    <img src={about1} alt="eitmam land" />
                    <p>
                        <h2 style={{
                            textAlign: i18n.language === "en" ? "left" : "right",

                        }}>{t("about.title1")}</h2>
                        <br />
                        {t("about.whatWeDo")}
                        <br />
                        <br />
                        {t("about.whatWeDo1")}
                    </p>
                </div>
                <h1 style={{ textAlign: "center" }}>{t("about.title2")}</h1>
                <div className="about-main1">
                    <ul style={{
                        width: "100%",
                        textAlign: i18n.language === "en" ? "left" : "right",
                        direction: i18n.language === "en" ? "ltr" : "rtl",
                    }}>
                        <li><span>{t("about.goalsTitle")}</span>{t("about.goals")}</li>
                        <br />
                        <li><span>{t("about.goalsTitle1")}</span>{t("about.goals1")}</li>
                        <br />
                        <li><span>{t("about.goalsTitle2")}</span>{t("about.goals2")}</li>
                        <br />
                        <li><span>{t("about.goalsTitle3")}</span>{t("about.goals3")}</li>
                    </ul>
                </div>
                <h1 style={{ textAlign: "center" }}>{t("about.title3")}</h1>
                <div className="about-main1">
                    <img src={mohre} alt="eitmam land" />
                    <img src={federalAuthority} alt="eitmam land" />
                    <img src={shamTravels} alt="eitmam land" />
                </div>
            </div>
        </>
    )
}
