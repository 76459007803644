const dateUtils = {
  // Get the current date in the format YYYY-MM-DD
  getCurrentDate: () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  // Format a date object to a custom date string
  formatDate: (dateObject, format = "YYYY-MM-DD") => {
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    format = format.replace("YYYY", year);
    format = format.replace("MM", month);
    format = format.replace("DD", day);

    return format;
  },

  // Add days to a given date
  addDays: (dateObject, days) => {
    const newDate = new Date(dateObject);
    newDate.setDate(dateObject.getDate() + days);
    return newDate;
  },

  // Calculate the difference in days between two dates
  dateDifferenceInDays: (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays;
  },

  // Check if a date is in the past
  isPastDate: (dateObject) => {
    const currentDate = new Date();
    return dateObject < currentDate;
  },

  // Check if a date is in the future
  isFutureDate: (dateObject) => {
    const currentDate = new Date();
    return dateObject > currentDate;
  },
  formatTimestampToTime: (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour: "2-digit", minute: "2-digit", hour12: false };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  },
  unixToISO: (timestamp) => new Date(timestamp * 1000).toISOString(),
  calculateOneYearAfter: (dateString) => {
    const givenDate = new Date(dateString);
    const oneYearAfter = new Date(givenDate);
    oneYearAfter.setFullYear(givenDate.getFullYear() + 1);
    // Format the date as yyyy-mm-dd
    const year = oneYearAfter.getFullYear();
    const month = String(oneYearAfter.getMonth() + 1).padStart(2, "0");
    const day = String(oneYearAfter.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  },
  getNextOrSameDate: (oldDate) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const oldDateObj = new Date(oldDate);
    const oldDay = oldDateObj.getDate();
    let nextDate = new Date(currentDate);

    if (currentDay >= oldDay) {
      nextDate.setMonth(currentDate.getMonth() + 1);
    }
    nextDate.setDate(oldDay);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, "0");
    const day = String(nextDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  },
  calculateTotalLeaveDays: (leaveForm) => {
    if (!leaveForm.startDate || !leaveForm.endDate) {
      return 0;
    }

    const startDate = new Date(leaveForm.startDate);
    const endDate = new Date(leaveForm.endDate);

    if (isNaN(startDate) || isNaN(endDate)) {
      return 0;
    }

    const timeDifference = endDate - startDate;
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    // If the dates are the same, count it as 1 day
    return dayDifference === 0 ? 1 : dayDifference + 1;
  },
  getLastDateOfMonth: (date) => {
    // Create a new date object with the current year and month
    const year = date.getFullYear();
    const month = date.getMonth();

    // Set the date to the first day of the next month and subtract one day
    const lastDate = new Date(year, month + 1, 0);
    return lastDate;
  },
};

export default dateUtils;
