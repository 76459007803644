import React, { useEffect } from "react";
import Widgets from "../../../components/global/widgets/Widgets";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../../../assets/styles/HrDashboard.css";
import { MdFlight, MdSick } from "react-icons/md";
import { IoInformationCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../../assets/i18n/i18n";
import { FaUser } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { getLeavesByUserId } from "../../../libs/leaves/getLeaves/getLeavesFunctions";
import { setUserLeaves } from "../../../redux/reduxSlice/leavesSlice";
import { getRequestsByUserId } from "../../../libs/requests/getRequests/getRequestFunctions";
import { setUserRequests } from "../../../redux/reduxSlice/requestsSlice";

export default function Dashboard() {
  const dispatch = useDispatch()
  const size = 25;
  const { t } = useTranslation();
  const hour = new Date().getHours();
  const { user } = useSelector((state) => state.user);
  const { userLeaves } = useSelector((state) => state.leaves);
  const { userRequests } = useSelector((state) => state.requests);
  const sickLeaves = userLeaves?.filter((item) => item?.type === "sickLeave")
  const annualLeaves = userLeaves?.filter((item) => item?.type === "annualLeave")
  useEffect(() => {
    const fetchData = async () => {
      const leaves = await getLeavesByUserId(user?.userId)
      dispatch(setUserLeaves(leaves))
      const requests = await getRequestsByUserId(user?.userId)
      dispatch(setUserRequests(requests))
    }
    fetchData()// eslint-disable-next-line
  }, [])

  let greeting;

  if (hour < 12) {
    greeting =
      i18n.language === "en"
        ? t("dashboardHr.greetingMorning")
        : t("dashboardHr.greetingMorning");
  } else {
    greeting =
      i18n.language === "en"
        ? t("dashboardHr.greetingEvening")
        : t("dashboardHr.greetingEvening");
  }
  const navigate = useNavigate();
  const { width } = useSelector((state) => state.global);
  const data = [
    {
      title:
        i18n.language === "en"
          ? t("dashboard.sickLeave")
          : t("dashboard.profile"),
      subtitle:
        i18n.language === "en"
          ? t("dashboard.totalSickLeave")
          : t("dashboard.viewProfile"),
      value: i18n.language === "en" ? sickLeaves?.length : <IoIosSettings size={size} />,
      icon1: <KeyboardArrowUpIcon />,
      icon:
        i18n.language === "en" ? (
          <MdSick size={size} />
        ) : (
          <FaUser size={size} />
        ),
      onClick:
        i18n.language === "en"
          ? () => navigate("/eitmamland/user/sickLeave")
          : () => navigate("/eitmamland/user/profile"),
    },
    {
      title: i18n.language === "en"
        ? t("dashboard.annualLeave") : t("dashboard.requests"),
      subtitle: i18n.language === "en"
        ? t("dashboard.totalAnnualLeave") : t("dashboard.totalRequests"),
      value: i18n.language === "en" ? annualLeaves?.length : userRequests?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon: i18n.language === "en" ? <MdFlight size={size} /> : <IoInformationCircleSharp size={size} />,
      onClick: i18n.language === "en"
        ? () => navigate("/eitmamland/user/annualLeave") : () => navigate("/eitmamland/user/requests"),
    },
    {
      title: i18n.language === "en"
        ? t("dashboard.requests") : t("dashboard.annualLeave"),
      subtitle: i18n.language === "en"
        ? t("dashboard.totalRequests") : t("dashboard.totalAnnualLeave"),
      value: i18n.language === "en" ? userRequests?.length : annualLeaves?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon: i18n.language === "en" ? <IoInformationCircleSharp size={size} /> : <MdFlight size={size} />,
      onClick: i18n.language === "en"
        ? () => navigate("/eitmamland/user/requests") : () => navigate("/eitmamland/user/annualLeave"),
    },
    {
      title:
        i18n.language === "en"
          ? t("dashboard.profile")
          : t("dashboard.sickLeave"),
      subtitle:
        i18n.language === "en"
          ? t("dashboard.viewProfile")
          : t("dashboard.totalSickLeave"),
      value: i18n.language === "en" ? <IoIosSettings size={size} /> : sickLeaves?.length,
      icon1: <KeyboardArrowUpIcon />,
      icon:
        i18n.language === "en" ? <FaUser size={size} /> : <MdSick size={size} />,
      onClick:
        i18n.language === "en"
          ? () => navigate("/eitmamland/user/profile")
          : () => navigate("/eitmamland/user/sickLeave"),
    },
  ];

  return (
    <>
      <div className="global-class">
        <h2
          className="hr-dashboard-main"
          style={{
            paddingLeft: i18n.language === "en" && width > 900 ? "14.5%" : 0,
            paddingRight: i18n.language !== "en" && width > 900 ? "14.5%" : 0,
            justifyContent: i18n.language !== "en" ? "flex-end" : "flex-start",
          }}
        >
          {i18n.language === "en" ? (
            <>
              <span className="time">{greeting}</span>{" "}
              <span>{t("dashboardHr.welcomeText")}</span>{" "}
            </>
          ) : (
            <>
              <span>{t("dashboardHr.welcomeText")}</span>{" "}
              <span className="time">{greeting}</span>
            </>
          )}
        </h2>
        <div
          className="hr-dashboard"
          style={{
            paddingLeft: i18n.language === "en" && width > 900 ? "10rem" : 0,
            paddingRight: i18n.language !== "en" && width > 900 ? "10rem" : 0,
          }}
        >
          {data.map((item) => (
            <div key={item.id} style={{ margin: "0.5rem", cursor: "pointer" }}>
              <Widgets item={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

