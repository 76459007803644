import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { TbError404 } from "react-icons/tb";

export default function ErrorScreen() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000); // eslint-disable-next-line
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            flexDirection: "column",
            background: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "100vh",
            textAlign: "center",
          }}
        >
          <TbError404 size={100} />
          <div>
            <h2>Sorry, We can't find the page you're looking for.</h2>
          </div>
          <Button variant="contained" onClick={() => navigate("/")}>
            Back to Home
          </Button>
        </div>
      </div>
    </>
  );
}
