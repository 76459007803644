import React from "react";
import PassportNumberAndImage from "./PassportNumberAndImage";
import PassportIssueAndExpiry from "./PassportIssueAndExpiry";

export default function PassportDetails() {
  return (
    <>
      {/* PassportNumberAndImage */}
      <PassportNumberAndImage />
      {/* PassportIssueAndExpiry */}
      <PassportIssueAndExpiry />
    </>
  );
}
