import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";

export default function AddressAndNationality() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={state?.address}
          size="small"
          type="text"
          label={t("employees.address")}
          placeholder="e.g. Baniyas, Abu Dhabi, UAE"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={state?.nationality}
          size="small"
          label={t("employees.nationality")}
          placeholder="e.g. Emirati"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
