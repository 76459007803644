import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, TextField } from "@mui/material";
import { logo } from "../../../../../assets/constants/global";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { AiOutlineCamera } from "react-icons/ai";

export default function EmirateNumberAndImage() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <div className="global-main" style={{ flexDirection: "column" }}>
          <div className="image-main-div">
            <div className="image-inner-div">
              <Avatar
                src={
                  editEmployeeForm?.emirateImageNew !== undefined
                    ? URL.createObjectURL(editEmployeeForm?.emirateImageNew) :
                    editEmployeeForm?.emirateImage !== undefined
                      ? editEmployeeForm?.emirateImage
                      : logo
                }
                alt={t("employees.emirateImage")}
                style={{ width: 100, height: 100 }}
              />
              <label style={{ cursor: "pointer" }}
                htmlFor={editEmployeeForm?.emirateImage !== undefined ? "emirateImage" : "emirateImageNew"}>
                <div style={{ position: "relative" }}>
                  <span>
                    <AiOutlineCamera size={28} className="image-icon" />
                  </span>
                </div>
                <input
                  id={editEmployeeForm?.emirateImage !== undefined ? "emirateImage" : "emirateImageNew"}
                  name={editEmployeeForm?.emirateImage !== undefined ? "emirateImage" : "emirateImageNew"}
                  autoComplete={editEmployeeForm?.emirateImage !== undefined ? "emirateImage" : "emirateImageNew"}
                  autoFocus
                  type="file"
                  hidden
                  size="small"
                  placeholder={t("employees.emirateImage")}
                  onChange={(e) =>
                    handleEditEmployeeInputChange(
                      e,
                      editEmployeeForm,
                      dispatch, t, employees
                    )
                  }
                />
                <br />
              </label>
            </div>
          </div>
          <h3>{t("employees.emirateImage")}</h3>
        </div>
        <TextField
          fullWidth
          id="emirateNumber"
          name="emirateNumber"
          autoComplete="emirateNumber"
          autoFocus
          error={!!editEmployeeErrors?.emirateNumber}
          helperText={editEmployeeErrors?.emirateNumber}
          value={editEmployeeForm?.emirateNumber}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }
          size="small"
          type="number"
          label={t("employees.emirateNumber")}
          placeholder="e.g.  784-1978-5467654-0"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
