import React, { useEffect } from "react";
import i18n from "../../../assets/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../redux/reduxSlice/globalSlice";
import { Tabs } from "antd";
import Loading from "../../../components/global/loading/Loading";
import TopButtons from "../../../components/supervisor/requestsSupervisor/requestsSupervisorComponents/TopButtons";
import Pending from "../../../components/supervisor/requestsSupervisor/Pending";
import Approved from "../../../components/supervisor/requestsSupervisor/Approved";
import Rejected from "../../../components/supervisor/requestsSupervisor/Rejected";
import { fetchRequestData } from "../../../libs/requests/getRequests/getRequestFunctions";

export default function RequestsSupervisor() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { width, loading } = useSelector((state) => state.global);
    useEffect(() => {
        fetchRequestData(dispatch)
        dispatch(setSearch("")); // eslint-disable-next-line
    }, []);
    const items = [
        {
            key: "1",
            label: t("global.pending"),
            children: <Pending />,
        },
        {
            key: "2",
            label: t("global.approved"),
            children: <Approved />,
        },
        {
            key: "3",
            label: t("global.rejected"),
            children: <Rejected />,
        },
    ];
    if (loading) return <Loading />
    return (
        <>
            <div
                className="global-class global-main-outer"
                style={{
                    paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
                    paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
                }}
            >
                {/* TopButtons */}
                <TopButtons />
                {/* Tabs */}
                <div className="global-main">
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size="large"
                        style={{ width: "100%" }}
                        items={items}
                    />
                </div>
            </div>
        </>
    );
}
