import React from "react";
import "../../../assets/styles/Widgets.css";
import i18n from "../../../assets/i18n/i18n";

const Widgets = ({ item }) => {
  return (
    <>
      <div className="summary-box" onClick={item.onClick}>
        {i18n.language === "en" ? (
          <>
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>{item.title}</div>
                <span>{item.subtitle}</span>
              </div>
              <div className="summary-box__info__value">{item.value}</div>
            </div>
            <div className="icon1">
              <div>{item.icon1}</div>
              <div>{item.icon}</div>
            </div>
          </>
        ) : (
          <>
            <div className="icon1">
              <div>{item.icon1}</div>
              <div>{item.icon}</div>
            </div>
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>{item.title}</div>
                <span>{item.subtitle}</span>
              </div>
              <div className="summary-box__info__value">{item.value}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Widgets;
