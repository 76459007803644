import { setNewEmployeeErrors } from "../../../redux/reduxSlice/employeesSlice";
import validationUtils from "../../../utils/validationUtils";

export const validateAddNewEmployee = (dispatch, name, value, t, newEmployeeForm, employees) => {
    if (name === "englishName") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorEnglishName"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "email") {
        const validEmail = validationUtils?.isValidEmail(value)
        const emailExists = employees?.some(employee => employee.email === value);
        if (!validEmail) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorEmail"),
                    })
                );
            });
        } else if (emailExists) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorEmailExist", { value }), // Adjusted translation
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "phoneNumber") {
        const validPhoneNumber = validationUtils?.isValidPhoneNumber(value)
        if (!validPhoneNumber) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorPhoneNumber"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "remainingAnnualLeaves") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorRemainingAnnualLeaves"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "remainingSickLeaves") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("employees.errorRemainingSickLeaves"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "password") {
        const validPassword = validationUtils?.isPasswordValid(value)
        if (!validPassword) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("global.errorPassword"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "confirmPassword") {
        if (value !== newEmployeeForm.password) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: t("global.errorConfirmPassword"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newEmployeeErrors;
                dispatch(
                    setNewEmployeeErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else {
        dispatch((dispatch, getState) => {
            const currentErrors = getState().employees.newEmployeeErrors;
            dispatch(
                setNewEmployeeErrors({
                    ...currentErrors,
                    [name]: "",
                })
            );
        });
    }
};


export const validateAllFieldsAddNewEmployee = (dispatch, newEmployeeForm, newEmployeeErrors, employees, t) => {
    let errors = { ...newEmployeeErrors }
    if (newEmployeeForm.englishName === "") {
        errors.englishName = t("employees.errorEnglishName");
    }
    if (newEmployeeForm.email === "" || !validationUtils.isValidEmail(newEmployeeForm.email)) {
        errors.email = t("employees.errorEmail");
    } else if (employees.some(employee => employee.email === newEmployeeForm.email)) {
        errors.email = t("employees.errorEmailExist", { value: newEmployeeForm.email });
    }
    if (newEmployeeForm.phoneNumber === "" || !validationUtils.isValidPhoneNumber(newEmployeeForm.phoneNumber)) {
        errors.phoneNumber = t("employees.errorPhoneNumber");
    }
    if (newEmployeeForm.remainingAnnualLeaves === "") {
        errors.remainingAnnualLeaves = t("employees.errorRemainingAnnualLeaves");
    }
    if (newEmployeeForm.remainingSickLeaves === "") {
        errors.remainingSickLeaves = t("employees.errorRemainingSickLeaves");
    }
    if (newEmployeeForm.password === "" || !validationUtils.isPasswordValid(newEmployeeForm.password)) {
        errors.password = t("global.errorPassword");
    }
    if (newEmployeeForm.password === "" || newEmployeeForm.confirmPassword !== newEmployeeForm.password) {
        errors.confirmPassword = t("global.errorConfirmPassword");
    }
    if (newEmployeeForm.gender === "") {
        errors.gender = t("employees.errorGender");
    }
    if (newEmployeeForm.role === "") {
        errors.role = t("employees.errorRole");
    }
    dispatch(setNewEmployeeErrors(errors));
    return errors
};

