import { message } from "antd";
import {
    setEmployees,
} from "../../redux/reduxSlice/employeesSlice";
import {
    setLoading
} from "../../redux/reduxSlice/globalSlice";
import {
    collection, doc, getDoc, getDocs,
    query,
    where
} from "firebase/firestore";
import { db } from "../../firebase/firebase";

const userRef = collection(db, "users");

const getUsers = async () => {
    const data = await getDocs(userRef);
    return data.docs.map((doc) => {
        const userData = { ...doc.data() };

        // Remove the password field if it exists
        if (userData.password) {
            delete userData.password;
        }

        return userData;
    });
};


const getUserById = async (userId) => {
    try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            const userData = { ...userDoc.data() };
            return userData;
        } else {
            message.error("No such document!");
            return null;
        }
    } catch (error) {
        return error;
    }
};


const getUserByRole = async (role) => {
    try {
        // Reference to the 'users' collection
        const usersCollectionRef = collection(db, 'users');

        // Create a query against the collection
        const q = query(usersCollectionRef, where('role', '==', role));

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Check if the query returned any documents
        if (!querySnapshot.empty) {
            // Map through documents and return an array of user data without the password field
            const users = querySnapshot.docs.map(doc => {
                const userData = doc.data();

                // Remove the password field if it exists
                if (userData.password) {
                    delete userData.password;
                }

                return userData;
            });
            return users;
        } else {
            console.log("No users found with the specified role.");
            return [];
        }

    } catch (error) {
        console.error("Error fetching documents:", error);
        // Return an empty array in case of error
        return [];
    }
};

const fetchData = async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const employeesArray = await getUsers();
        dispatch(setEmployees(employeesArray));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        message.error(error.message);
    }
};

const fetchAndCompareUserById = async (storedUser) => {
    try {
        // Fetch the user from Firestore by userId
        const userData = await getUserById(storedUser?.userId);

        if (userData) {
            // Compare all keys
            const userKeys = Object.keys(userData);
            const storedKeys = Object.keys(storedUser);



            // Check if any of the keys are different
            const hasChanges = userKeys.some((key) => {
                // Only check keys that exist in storedUser
                if (storedKeys.includes(key)) {
                    return userData[key] !== storedUser[key];
                }
                return false; // Ignore keys not present in storedUser
            });

            if (hasChanges) {
                // Clear localStorage and reload the page
                message.error("User data has changed. Logged out.");
                localStorage.clear();
                window.location.reload();

            } else {
                console.log("No changes detected. User remains logged in.");

            }
        } else {
            console.log("User does not exist in Firestore.");
        }
    } catch (error) {
        console.error("Error fetching user by ID:", error);
    }
};



export {
    fetchData, getUserById, getUserByRole, fetchAndCompareUserById
}