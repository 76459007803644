import React from 'react'
import i18n from "../../../assets/i18n/i18n";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/Employees.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, InputAdornment, TextField } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";

export default function ViewSickLeave() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { width } = useSelector((state) => state.global);
  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <div className="global-main">
          <Button
            onClick={() => navigate("/eitmamland/user/sickLeave")}
            sx={{
              margin: "0.5rem 0rem",
              background: "#d4df98",
              "&:hover": {
                background: "#a4b964",
              },
            }}
            variant="contained"
          >
            {t("leaves.goBack")}
          </Button>
        </div>
        {/* heading */}
        <div className="global-main">
          <h2
            className="add-employees-heading"
            style={{
              justifyContent:
                i18n.language !== "en" ? "flex-end" : "flex-start",
            }}
          >
            {t("leaves.viewSickLeave")}
          </h2>
        </div>
        {/* start and end date */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="startDate"
            name="startDate"
            autoComplete="startDate"
            autoFocus
            inputProps={{ readOnly: true }}
            value={state?.startDate}
            size="small"
            type="date"
            label={t("leaves.startDate")}
            placeholder="e.g. 27/05/2024"
            sx={{ margin: "0.5rem" }}
          />
          <TextField
            required
            fullWidth
            id="endDate"
            name="endDate"
            autoComplete="endDate"
            autoFocus
            inputProps={{ readOnly: true }}
            value={state?.endDate}
            size="small"
            type="date"
            label={t("leaves.endDate")}
            placeholder="e.g. 27/05/2024"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        {/* total Leave Days */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="totalLeaveDays"
            name="totalLeaveDays"
            autoComplete="totalLeaveDays"
            autoFocus
            value={state?.totalLeaveDays}
            size="small"
            type="text"
            inputProps={{ readOnly: true }}
            label={t("leaves.totalLeaveDays")}
            placeholder="e.g. 2"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        {/* description and attachements */}
        <div className="global-main">
          <TextField
            required
            fullWidth
            id="description"
            name="description"
            autoComplete="description"
            autoFocus
            inputProps={{ readOnly: true }}
            value={state?.description}
            size="small"
            type="text"
            multiline
            rows={6}
            label={t("leaves.description")}
            placeholder="e.g. leave details"
            sx={{ margin: "0.5rem" }}
          />
        </div>
        {/* attachments view */}
        {state?.attachments?.length > 0 ?
          <>
            {/* attachments */}
            <div className="global-main">
              <h2
                className="add-employees-heading"
                style={{
                  justifyContent:
                    i18n.language !== "en" ? "flex-end" : "flex-start",
                }}
              >
                {t("leaves.attachments")}
              </h2>
            </div>
            {state?.attachments?.map((item, i) => {
              return (
                <>
                  <div className="global-main">
                    <TextField
                      fullWidth
                      sx={{ margin: "0.5rem", cursor: "pointer" }}
                      autoFocus
                      value={item?.name ? item?.name : `Attachment-${i + 1}`}
                      onClick={() => window.open(item, '_blank')}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            {item?.type === "application/pdf" ? (
                              <Avatar sx={{ background: "#FAF9F6" }}>
                                <FaRegFilePdf size={25} color="red" />
                              </Avatar>
                            ) : (
                              <Avatar
                                src={item}
                                alt="Attachment"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }} />
                  </div>
                </>

              )
            })}
          </> : ""}
      </div >
    </>
  )
}
