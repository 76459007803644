import React from "react";
import { useNavigate } from "react-router";
import { Copyright } from "../../utils/copyright";
import { useTranslation } from "react-i18next";
import i18n from "../../assets/i18n/i18n";
import "../../assets/styles/Footer.css";

export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`footer-main ${i18n.language === "en" ? "margin-left" : "margin-right"
          }`}
      >
        <div className="footer-divider" />
        {i18n.language === "en" ? (
          <>
            <div className="footer-inner">
              <div onClick={() => navigate("/")} className="footer-content">
                {t("header.home")}
              </div>
              <div
                onClick={() => navigate("/about")}
                className="footer-content"
              >
                {t("header.about")}
              </div>
              <div
                onClick={() => navigate("/services")}
                className="footer-content"
              >
                {t("header.services")}
              </div>
              <div
                onClick={() => navigate("/contact")}
                className="footer-content"
              >
                {t("header.contact")}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="footer-inner1">
              <div
                onClick={() => navigate("/contact")}
                className="footer-content"
              >
                {t("header.contact")}
              </div>
              <div
                onClick={() => navigate("/services")}
                className="footer-content"
              >
                {t("header.services")}
              </div>
              <div
                onClick={() => navigate("/about")}
                className="footer-content"
              >
                {t("header.about")}
              </div>
              <div onClick={() => navigate("/")} className="footer-content">
                {t("header.home")}
              </div>
            </div>
          </>
        )}
        {/* Copyright component */}
        <Copyright sx={{ mt: 0.5, mb: 0.5 }} t={t} color="black" />
      </div>
    </>
  );
}
