import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";

export default function AnnualLeaves() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={state?.remainingAnnualLeaves}
          size="small"
          label={t("employees.remainingAnnualLeaves")}
          placeholder="e.g. 20"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={state?.remainingSickLeaves}
          size="small"
          label={t("employees.remainingSickLeaves")}
          placeholder="e.g.10"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
