import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

export default function PassportIssueAndExpiry() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={user?.passportIssue}
          size="small"
          type="text"
          label={t("employees.passportIssue")}
          placeholder="e.g.  18/05/2023"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={user?.passportExpiry}
          size="small"
          label={t("employees.passportExpiry")}
          placeholder="e.g. 18/05/2028"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
