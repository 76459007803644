import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import dateUtils from "../../../../../utils/dateUtils";

export default function EmirateIssueAndExpiry() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();

  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="emirateIssue"
          name="emirateIssue"
          autoComplete="emirateIssue"
          autoFocus
          error={!!editEmployeeErrors?.emirateIssue}
          helperText={editEmployeeErrors?.emirateIssue}
          value={editEmployeeForm?.emirateIssue === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.emirateIssue}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.emirateIssue")}
          placeholder="e.g.  18/02/2020"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="emirateExpiry"
          name="emirateExpiry"
          autoComplete="emirateExpiry"
          autoFocus
          error={!!editEmployeeErrors?.emirateExpiry}
          helperText={editEmployeeErrors?.emirateExpiry}
          value={editEmployeeForm?.emirateExpiry === undefined ? dateUtils?.getCurrentDate() : editEmployeeForm?.emirateExpiry}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="date"
          label={t("employees.emirateExpiry")}
          placeholder="e.g. 18/02/2024"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
