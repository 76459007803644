import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Avatar } from "@mui/material";
import { user } from "../../../../../assets/constants/global";
import { handleOpenImage } from "../../../../../utils/openImage";

export default function ProfileImage() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main" style={{ flexDirection: "column" }}>
        <Avatar
          src={state?.profileImage === undefined ? user : state?.profileImage}
          alt={t("employees.profileImage")}
          style={{ width: 100, height: 100 }}
          onClick={() => handleOpenImage(state?.profileImage === undefined ? user : state?.profileImage)}
        />
        <h3>{t("employees.profileImage")}</h3>
      </div>
    </>
  );
}
