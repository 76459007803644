import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";

export default function ArabicNameAndPersonalEmail() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="arabicName"
          name="arabicName"
          autoComplete="arabicName"
          autoFocus
          error={!!editEmployeeErrors?.arabicName}
          helperText={editEmployeeErrors?.arabicName}
          value={editEmployeeForm?.arabicName}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="text"
          label={t("employees.arabicName")}
          placeholder="e.g.   محمد فهد"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="personalEmail"
          name="personalEmail"
          autoComplete="personalEmail"
          autoFocus
          error={!!editEmployeeErrors?.personalEmail}
          helperText={editEmployeeErrors?.personalEmail}
          value={editEmployeeForm?.personalEmail}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="email"
          label={t("employees.personalEmail")}
          placeholder="e.g.  email@gmail.com"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
