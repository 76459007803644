import React from "react";
import ReactLoading from "react-loading";

export default function Loading() {
  return (
    <>
      <div
        style={{
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <ReactLoading type="spin" color="#daa520" height="5%" width="5%" />
      </div>
    </>
  );
}
