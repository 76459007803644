import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { handleNewEmployeeInputChange } from "../../../../libs/employees/addNewEmployee/addNewEmployeeFunctions";

export default function RoleAndGender() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newEmployeeForm, newEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  return (
    <>
      <div className="global-main">
        <FormControl fullWidth sx={{ margin: "0.5rem" }} error={!!newEmployeeErrors?.role}
          helperText={newEmployeeErrors?.role}>
          <InputLabel id="role">{t("employees.role")}</InputLabel>
          <Select
            size="small"
            placeholder="e.g. user"
            labelId="role"
            required
            fullWidth
            id="role"
            name="role"
            autoComplete="role"
            autoFocus
            error={!!newEmployeeErrors?.role}
            helperText={newEmployeeErrors?.role}
            value={newEmployeeForm?.role}
            label={t("employees.role")}
            onChange={(e) =>
              handleNewEmployeeInputChange(
                e,
                newEmployeeForm,
                dispatch,
                t,
                employees
              )
            }
          >
            <MenuItem value={"user"}>{t("employees.user")}</MenuItem>
            <MenuItem value={"hr"}>{t("employees.hr")}</MenuItem>
            <MenuItem value={"supervisor"}>{t("employees.supervisor")}</MenuItem>
          </Select>
          {!!newEmployeeErrors?.role && (
            <FormHelperText>{newEmployeeErrors.role}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth sx={{ margin: "0.5rem" }} error={!!newEmployeeErrors?.gender}
        >
          <InputLabel id="gender">{t("employees.gender")}</InputLabel>
          <Select
            size="small"
            placeholder="e.g. male"
            labelId="gender"
            required
            fullWidth
            id="gender"
            name="gender"
            autoComplete="gender"
            autoFocus
            error={!!newEmployeeErrors?.gender}
            helperText={newEmployeeErrors?.gender}
            value={newEmployeeForm?.gender}
            label={t("employees.gender")}
            onChange={(e) =>
              handleNewEmployeeInputChange(
                e,
                newEmployeeForm,
                dispatch,
                t,
                employees
              )
            }
          >
            <MenuItem value={"male"}>{t("employees.male")}</MenuItem>
            <MenuItem value={"female"}>{t("employees.female")}</MenuItem>
          </Select>
          {!!newEmployeeErrors?.gender && (
            <FormHelperText>{newEmployeeErrors.gender}</FormHelperText>
          )}
        </FormControl>
      </div>
    </>
  );
}
