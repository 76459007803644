import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { handleNewEmployeeInputChange } from "../../../../libs/employees/addNewEmployee/addNewEmployeeFunctions";
import dateUtils from "../../../../utils/dateUtils";

export default function PhoneNumberAndDateOfJoining() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newEmployeeForm, newEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="phoneNumber"
          name="phoneNumber"
          autoComplete="phoneNumber"
          autoFocus
          error={!!newEmployeeErrors?.phoneNumber}
          helperText={newEmployeeErrors?.phoneNumber}
          value={newEmployeeForm?.phoneNumber}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          size="small"
          type="text"
          label={t("employees.phoneNumber")}
          placeholder="e.g. +971528997686"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          required
          fullWidth
          id="dateOfJoining"
          name="dateOfJoining"
          autoComplete="dateOfJoining"
          autoFocus
          error={!!newEmployeeErrors?.dateOfJoining}
          helperText={newEmployeeErrors?.dateOfJoining}
          value={newEmployeeForm?.dateOfJoining}
          onChange={(e) =>
            handleNewEmployeeInputChange(
              e,
              newEmployeeForm,
              dispatch,
              t,
              employees
            )
          }
          defaultValue={dateUtils.getCurrentDate()}
          size="small"
          type="date"
          label={t("employees.dateOfJoining")}
          placeholder="e.g. 27/05/2024"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
