import { message } from "antd";
import dateUtils from "../../utils/dateUtils";
import { fetchLeaveData } from "../leaves/getLeaves/getLeavesFunctions";
import { clearGlobalStates, setLoading } from "../../redux/reduxSlice/globalSlice";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { fetchRequestData } from "../requests/getRequests/getRequestFunctions";
// import { sendNotificationToUser } from "../../utils/pushNotificationFunc";
import { sendEmailToUser } from "../../utils/sendEmail";


export const handleSubmit = async (e, responseModalForm, responseModal, dispatch, t, state, navigate, user) => {
    try {
        e.preventDefault()
        let data;
        let remainingAnnualLeaves = state.remainingAnnualLeaves;
        let remainingSickLeaves = state.remainingSickLeaves;
        if (user?.role === "hr") {
            data = {
                updatedAt: dateUtils?.getCurrentDate(),
                hrComments: responseModalForm,
                hrStatus: responseModal?.response,
            }
        } else if (user?.role === "supervisor") {
            if (state?.type === "annualLeave" && responseModal?.response === "approved") {
                remainingAnnualLeaves = parseFloat(state?.remainingAnnualLeaves) - parseFloat(state?.totalLeaveDays)
            } else if (state?.type === "sickLeave" && responseModal?.response === "approved") {
                remainingSickLeaves = parseFloat(state?.remainingSickLeaves) - parseFloat(state?.totalLeaveDays)
            };
            data = {
                updatedAt: dateUtils?.getCurrentDate(),
                supervisorComments: responseModalForm,
                supervisorStatus: responseModal?.response,
                remainingAnnualLeaves,
                remainingSickLeaves
            }
            const userData = {
                updatedAt: dateUtils?.getCurrentDate(),
                remainingAnnualLeaves,
                remainingSickLeaves
            }
            const userRef = doc(db, "users", state?.userId);
            await setDoc(userRef, userData, { merge: true });
        }
        const leaveRef = doc(db, "leaves", state?.leaveId);
        await setDoc(leaveRef, data, { merge: true });
        // await sendNotificationToUser(state?.type, state?.userId, responseModal?.response, user?.role)
        await sendEmailToUser(state?.type, state?.userId, responseModal?.response, user?.role, responseModalForm)
        await fetchLeaveData(dispatch);
        if (state?.type === "sickLeave") {
            if (user?.role === "user") {
                navigate("/eitmamland/user/sickLeave");
            } else if (user?.role === "hr") {
                navigate("/eitmamland/hr/sickLeave");
            } else {
                navigate("/eitmamland/supervisor/sickLeave");
            }
        } else if (state?.type === "annualLeave") {
            if (user?.role === "user") {
                navigate("/eitmamland/user/annualLeave");
            } else if (user?.role === "hr") {
                navigate("/eitmamland/hr/annualLeave");
            } else {
                navigate("/eitmamland/supervisor/annualLeave");
            }
        } else {
            if (user?.role === "user") {
                navigate("/eitmamland/user");
            } else if (user?.role === "hr") {
                navigate("/eitmamland/hr");
            } else {
                navigate("/eitmamland/supervisor");
            }
        }
        if (responseModal?.response === "approved") {
            message.success(t("leaves.approveSuccess"));
        } else {
            message.success(t("leaves.rejectSuccess"));
        }
        dispatch(setLoading(false));
        dispatch(clearGlobalStates());
    } catch (error) {
        message.error(t("leaves.leaveError"));
    }
}

export const handleSubmitRequest = async (e, responseModalForm, responseModal, dispatch, t, state, navigate, user) => {
    try {
        e.preventDefault()
        let data;
        if (user?.role === "hr") {
            data = {
                updatedAt: dateUtils?.getCurrentDate(),
                hrComments: responseModalForm,
                hrStatus: responseModal?.response
            }
        } else if (user?.role === "supervisor") {
            data = {
                updatedAt: dateUtils?.getCurrentDate(),
                supervisorComments: responseModalForm,
                supervisorStatus: responseModal?.response
            }
        }
        const docRef = doc(db, "requests", state?.requestId);
        await setDoc(docRef, data, { merge: true });
        // await sendNotificationToUser(state?.type, state?.userId, responseModal?.response, user?.role)
        await sendEmailToUser(state?.type, state?.userId, responseModal?.response, user?.role, responseModalForm)
        await fetchRequestData(dispatch);
        if (user?.role === "user") {
            navigate("/eitmamland/user/requests");
        } else if (user?.role === "hr") {
            navigate("/eitmamland/hr/requests");
        } else {
            navigate("/eitmamland/supervisor/requests");
        }
        if (responseModal?.response === "approved") {
            message.success(t("requests.approveSuccess"));
        } else {
            message.success(t("requests.rejectSuccess"));
        }
        dispatch(setLoading(false));
        dispatch(clearGlobalStates());
    } catch (error) {
        message.error(t("requests.requestError"));
    }
}