import React from "react";
import ProfileImage from "./ProfileImage";
import ArabicNameAndPersonalEmail from "./ArabicNameAndPersonalEmail";
import MobileNumberAndDateOfBirth from "./MobileNumberAndDateOfBirth";
import AddressAndNationality from "./AddressAndNationality";
import ManagerAndPosition from "./ManagerAndPosition";
import CompanyNameAndAddress from "./CompanyNameAndAddress";

export default function ProfileDetails() {
  return (
    <>
      {/* ProfileImage */}
      <ProfileImage />
      {/* ArabicNameAndPersonalEmail */}
      <ArabicNameAndPersonalEmail />
      {/* MobileNumberAndDateOfBirth */}
      <MobileNumberAndDateOfBirth />
      {/* AddressAndNationality */}
      <AddressAndNationality />
      {/* ManagerAndPosition */}
      <ManagerAndPosition />
      {/* CompanyNameAndAddress */}
      <CompanyNameAndAddress />
    </>
  );
}
