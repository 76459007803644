import { createSlice } from "@reduxjs/toolkit";

const requestsSlice = createSlice({
    name: "requests",
    initialState: {
        newRequestForm: {
            title: "",
            description: "",
            attachments: [],
        },
        newRequestErrors: {
            title: "",
            description: "",
            attachments: "",
        },
        editRequestForm: {},
        editRequestErrors: {},
        requests: [],
        monthlyRequests: [],
        userRequests: [],
    },
    reducers: {
        setNewRequestForm: (state, action) => {
            state.newRequestForm = action.payload;
        },
        setNewRequestErrors: (state, action) => {
            state.newRequestErrors = action.payload;
        },
        setEditRequestForm: (state, action) => {
            state.editRequestForm = action.payload;
        },
        setEditRequestErrors: (state, action) => {
            state.editRequestErrors = action.payload;
        },
        setRequests: (state, action) => {
            state.requests = action.payload;
        },
        setMonthlyRequests: (state, action) => {
            state.monthlyRequests = action.payload;
        },
        setUserRequests: (state, action) => {
            state.userRequests = action.payload;
        },
        clearNewRequestForm: (state) => {
            state.newRequestForm = {
                title: "",
                description: "",
                attachments: [],
            };
            state.newRequestErrors = {
                title: "",
                description: "",
                attachments: "",
            };
        },
        clearEditRequestForm: (state) => {
            state.editRequestForm = {};
            state.editRequestErrors = {};
        },
    },
});

export const { setNewRequestForm, setUserRequests, setNewRequestErrors,
    setEditRequestForm, setEditRequestErrors, setRequests, setMonthlyRequests,
    clearNewRequestForm, clearEditRequestForm } = requestsSlice.actions;

export default requestsSlice.reducer;
