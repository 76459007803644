import React, { useEffect } from "react";
import i18n from "../../../assets/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../redux/reduxSlice/globalSlice";
import { Tabs } from "antd";
import Loading from "../../../components/global/loading/Loading";
import TopButtons from "../../../components/user/annualLeave/annualLeaveComponents/TopButtons";
import Pending from "../../../components/user/annualLeave/Pending";
import Approved from "../../../components/user/annualLeave/Approved";
import Rejected from "../../../components/user/annualLeave/Rejected";
import { getLeavesByUserId } from "../../../libs/leaves/getLeaves/getLeavesFunctions";
import { setUserLeaves } from "../../../redux/reduxSlice/leavesSlice";

export default function AnnualLeave() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width, loading } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    const fetchLeaves = async () => {
      const leaves = await getLeavesByUserId(user?.userId)
      dispatch(setUserLeaves(leaves))
    }
    fetchLeaves()
    dispatch(setSearch("")); // eslint-disable-next-line
  }, []);
  const items = [
    {
      key: "1",
      label: t("global.pending"),
      children: <Pending />,
    },
    {
      key: "2",
      label: t("global.approved"),
      children: <Approved />,
    },
    {
      key: "3",
      label: t("global.rejected"),
      children: <Rejected />,
    },
  ];
  if (loading) return <Loading />
  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <TopButtons />
        {/* Tabs */}
        <div className="global-main">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            style={{ width: "100%" }}
            items={items}
          />
        </div>
      </div>
    </>
  );
}

