import React, { useEffect } from "react";
import i18n from "../../../assets/i18n/i18n";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../redux/reduxSlice/globalSlice";
import { Tabs } from "antd";
import BasicDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/basicDetails/BasicDetails";
import EmiratesIdDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/emiratesIdDetails/EmiratesIdDetails";
import VisaDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/visaDetails/VisaDetails";
import SalaryDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/salaryDetails/SalaryDetails";
import PassportDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/passportDetails/PassportDetails";
import InsuranceDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/insuranceDetails/InsuranceDetails";
import ResidenceDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/residenceDetails/ResidenceDetails";
import WorkPermitDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/workPermitDetails/WorkPermitDetails";
import ProfileDetails from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/profileDetails/ProfileDetails";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import Loading from '../../../components/global/loading/Loading';
import { handleSubmit } from "../../../libs/employees/editEmployee/editEmployeeFunctions";
import "../../../assets/styles/Employees.css"
import { useNavigate } from "react-router-dom";
import TopButtons from "../../../components/supervisor/supervisorProfile/editSupervisorProfile/TopButtons";

export default function EditSupervisorProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { width, loading } = useSelector((state) => state.global);
  const { editEmployeeForm, editEmployeeErrors } = useSelector(
    (state) => state.employees
  );

  const { t } = useTranslation();
  useEffect(() => {
    if (Object.values(editEmployeeForm).length === 0) {
      navigate("/eitmamland/supervisor/profile")
    }
    dispatch(setSearch("")); // eslint-disable-next-line
  }, []);
  if (loading) return <Loading />
  const items = [
    {
      key: "1",
      label: t("employees.basicDetails"),
      children: <BasicDetails />,
    },
    {
      key: "2",
      label: t("employees.profileDetails"),
      children: <ProfileDetails />,
    },
    {
      key: "3",
      label: t("employees.emiratesIdDetails"),
      children: <EmiratesIdDetails />,
    },
    {
      key: "4",
      label: t("employees.salaryDetails"),
      children: <SalaryDetails />,
    },
    {
      key: "5",
      label: t("employees.passportDetails"),
      children: <PassportDetails />,
    },
    {
      key: "6",
      label: t("employees.visaDetails"),
      children: <VisaDetails />,
    },
    {
      key: "7",
      label: t("employees.insuranceDetails"),
      children: <InsuranceDetails />,
    },
    {
      key: "8",
      label: t("employees.residenceDetails"),
      children: <ResidenceDetails />,
    },
    {
      key: "9",
      label: t("employees.workPermitDetails"),
      children: <WorkPermitDetails />,
    },
  ];

  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <TopButtons />
        {/* Tabs */}
        <div className="global-main">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            style={{ width: "100%" }}
            items={items}
          />
        </div>
        <div className="global-main" style={{ justifyContent: "center" }}>
          <Button
            onClick={(e) => handleSubmit(e, editEmployeeForm, editEmployeeErrors, dispatch, navigate, t, "supervisorProfile")}
            sx={{
              margin: "0.5rem 0rem",
              background: "#d4df98",
              "&:hover": {
                background: "#a4b964",
              },
            }}
            variant="contained"
          >
            {t("employees.editButton")}
          </Button>
        </div>
      </div>
    </>
  );
}
