import React from "react";
import WorkPermitNumberAndImage from "./WorkPermitNumberAndImage";
import WorkPermitIssueAndExpiry from "./WorkPermitIssueAndExpiry";
import WorkPermitPersonalNumber from "./WorkPermitPersonalNumber";
import EmploymentAndApprovedContract from "./EmploymentAndApprovedContract";

export default function WorkPermitDetails() {
  return (
    <>
      {/* WorkPermitNumberAndImage */}
      <WorkPermitNumberAndImage />
      {/* WorkPermitIssueAndExpiry */}
      <WorkPermitIssueAndExpiry />
      {/* WorkPermitPersonalNumber */}
      <WorkPermitPersonalNumber />
      {/* EmploymentAndApprovedContract */}
      <EmploymentAndApprovedContract />
    </>
  );
}
