import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const RequestsBarChartSupervisor = ({ requestsData }) => {
    const [chartData, setChartData] = useState([]);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const processData = () => {
            // Create a mapping of months to request counts
            const data = requestsData.reduce((acc, request) => {
                const requestMonth = new Date(request.createdAt).toLocaleString('default', { month: 'short', year: 'numeric' });

                if (!acc[requestMonth]) {
                    acc[requestMonth] = { month: requestMonth, totalRequests: 0, pending: 0, approved: 0, rejected: 0 };
                }

                acc[requestMonth].totalRequests += 1;
                if (request.supervisorStatus === 'pending') acc[requestMonth].pending += 1;
                if (request.supervisorStatus === 'approved') acc[requestMonth].approved += 1;
                if (request.supervisorStatus === 'rejected') acc[requestMonth].rejected += 1;

                return acc;
            }, {});

            // Generate months for the current year dynamically
            const currentYear = new Date().getFullYear();
            const months = Array.from({ length: 12 }, (_, i) => {
                const date = new Date(currentYear, i, 1);
                return date.toLocaleString('default', { month: 'short', year: 'numeric' });
            });

            // Ensure all months are represented in chart data
            const completeData = months.map(month => data[month] || { month, totalRequests: 0, pending: 0, approved: 0, rejected: 0 });

            setChartData(completeData);
        };

        processData();
    }, [requestsData]);

    // Handle bar click event
    const handleBarClick = (data) => {
        const monthYear = data.payload.month; // Get month-year from the clicked bar data
        navigate(`/eitmamland/supervisor/monthlyRequests/${monthYear}`); // Navigate to the desired page with query params
    };

    return (
        <div style={{ width: '100%', height: 400 }}>
            {chartData.length === 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={[{ month: "No Data", pending: 0, approved: 0, rejected: 0 }]}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pending" fill="#ffcc00" name="Pending Requests" />
                        <Bar dataKey="approved" fill="#4caf50" name="Approved Requests" />
                        <Bar dataKey="rejected" fill="#f44336" name="Rejected Requests" />
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey="pending"
                            fill="#ffcc00"
                            name="Pending Requests"
                            onClick={handleBarClick} // Add click event handler
                            style={{ cursor: 'pointer' }}
                        />
                        <Bar
                            dataKey="approved"
                            fill="#4caf50"
                            name="Approved Requests"
                            onClick={handleBarClick} // Add click event handler
                            style={{ cursor: 'pointer' }}
                        />
                        <Bar
                            dataKey="rejected"
                            fill="#f44336"
                            name="Rejected Requests"
                            onClick={handleBarClick} // Add click event handler
                            style={{ cursor: 'pointer' }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default RequestsBarChartSupervisor;
