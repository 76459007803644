import { Button, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../../assets/i18n/i18n";
import { setSearch } from "../../../../redux/reduxSlice/globalSlice";
import { useDispatch, useSelector } from "react-redux";

export default function TopButtons() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { search } = useSelector((state) => state.global);
    return (
        <>
            <div className="global-main">
                <Button
                    onClick={
                        i18n.language === "en"
                            ? () => navigate("/eitmamland/hr")
                            : () => navigate("/eitmamland/hr/sickLeave/new")
                    }
                    sx={{
                        margin: "0.5rem 0rem",
                        background: "#d4df98",
                        "&:hover": {
                            background: "#a4b964",
                        },
                    }}
                    variant="contained"
                >
                    {i18n.language === "en" ? (
                        <>{t("global.dashboardButton")}</>
                    ) : (
                        <>{t("global.addNewLeave")}</>
                    )}
                </Button>
                <TextField
                    value={search}
                    onChange={(e) => dispatch(setSearch(e.target.value))}
                    size="small"
                    placeholder={t("leaves.searchLeaves")}
                    sx={{ margin: "0.5rem 0rem" }}
                />
                <Button
                    onClick={
                        i18n.language === "en"
                            ? () => navigate("/eitmamland/hr/sickLeave/new")
                            : () => navigate("/eitmamland/hr")
                    }
                    sx={{
                        margin: "0.5rem 0rem",
                        background: "#d4df98",
                        "&:hover": {
                            background: "#a4b964",
                        },
                    }}
                    variant="contained"
                >
                    {i18n.language === "en" ? (
                        <>{t("global.addNewLeave")}</>
                    ) : (
                        <>{t("global.dashboardButton")}</>
                    )}
                </Button>
            </div>
            <div className="global-main" style={{
                width: "98%",
                display: "flex",
                justifyContent: i18n.language === "en" ? "left" : "right",
            }}>
                <h2>{t("sidebar.sickLeave")}</h2>
            </div>
        </>
    );
}
