import React from "react";
import EmirateNumberAndImage from "./EmirateNumberAndImage";
import EmirateIssueAndExpiry from "./EmirateIssueAndExpiry";

export default function EmiratesIdDetails() {
  return (
    <>
      {/* EmirateNumberAndImage */}
      <EmirateNumberAndImage />
      {/* EmirateIssueAndExpiry */}
      <EmirateIssueAndExpiry />
    </>
  );
}
