import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

export default function TransportAndTotal() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={`${user?.transport === undefined ? 0 : user?.transport} aed`}
          size="small"
          type="text"
          label={t("employees.transport")}
          placeholder="e.g.  900 aed"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={`${user?.total === undefined ? 0 : user?.total} aed`}
          size="small"
          label={t("employees.total")}
          placeholder="e.g. 5000 aed"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
