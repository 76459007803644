import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { setLoading } from "../../../redux/reduxSlice/globalSlice";
import { setMonthlyRequests, setRequests, setUserRequests } from "../../../redux/reduxSlice/requestsSlice";
import { message } from "antd";
import dateUtils from "../../../utils/dateUtils";

const userRef = collection(db, "requests");

const getRequests = async () => {
    const data = await getDocs(userRef);
    return (data.docs.map((doc) => ({ ...doc.data() })));
};

const getRequestsOfCurrentMonth = async () => {
    const now = new Date();
    const month = now.getMonth() + 1; // getMonth() is zero-based, so add 1
    const year = now.getFullYear();

    // Calculate the first and last date of the current month
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0); // Last day of the month
    const formattedStartDate = dateUtils?.formatDate(startDate)
    const formattedEndDate = dateUtils?.formatDate(endDate)
    // Reference to the 'leaves' collection (assuming your collection is named 'leaves')
    const leavesRef = collection(db, 'requests');

    // Query for leaves within the date range
    const leavesQuery = query(
        leavesRef,
        where('createdAt', '>=', formattedStartDate),
        where('createdAt', '<=', formattedEndDate)
    );

    // Get the documents that match the query
    const querySnapshot = await getDocs(leavesQuery);

    // Map and return the data
    return querySnapshot.docs.map(doc => ({ ...doc.data() }));
};

export const getRequestsByUserId = async (userId) => {
    try {
        const leavesRef = collection(db, 'requests');
        const q = query(leavesRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } else {
            return [];
        }
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchRequestData = async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const requestsArray = await getRequests();
        dispatch(setRequests(requestsArray));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setRequests([]));
        dispatch(setLoading(false));
        message.error(error.message);
    }
}

export const fetchRequestOfMonthData = async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const requestsArray = await getRequestsOfCurrentMonth();
        dispatch(setMonthlyRequests(requestsArray));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setMonthlyRequests([]));
        dispatch(setLoading(false));
        message.error(error.message);
    }
}

export const updateRequestStatus = async (dispatch, state, user) => {
    try {
        const docRef = doc(db, "requests", state?.requestId);
        let data;
        if (user?.role === "hr") {
            data = {
                hrView: true,
                updatedAt: dateUtils?.getCurrentDate()
            }
        } else if (user?.role === "supervisor") {
            data = {
                supervisorView: true,
                updatedAt: dateUtils?.getCurrentDate()
            }
        } else return
        await setDoc(docRef, data, { merge: true });
        const userRequests = await getRequestsByUserId(user?.userId)
        setUserRequests(userRequests)
        const requestsArray = await getRequests();
        dispatch(setRequests(requestsArray));
    } catch (error) {
        dispatch(setRequests([]));
        dispatch(setLoading(false));
        message.error(error.message);
    }
}