import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

export default function AddressAndNationality() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={user?.address}
          size="small"
          type="text"
          label={t("employees.address")}
          placeholder="e.g. Baniyas, Abu Dhabi, UAE"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={user?.nationality}
          size="small"
          label={t("employees.nationality")}
          placeholder="e.g. Emirati"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
