import { Button } from "@mui/material";
import React from "react";
import i18n from "../../../../assets/i18n/i18n";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { setChangePasswordModal } from "../../../../redux/reduxSlice/globalSlice";
import ChangePassword from "../../../global/changePassword/ChangePassword";

export default function TopButtons() {
  const { user } = useSelector((state) => state.user);
  const { changePasswordModal } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <Button
          onClick={
            i18n.language === "en"
              ? () => navigate("/eitmamland/hr/profile")
              : () => dispatch(setChangePasswordModal(true))
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("hrProfile.backToProfile")}</>
          ) : (
            <>{t("global.changePassword")}</>
          )}
        </Button>
        <Button
          onClick={
            i18n.language === "en"
              ? () => dispatch(setChangePasswordModal(true))
              : () => navigate("/eitmamland/hr/profile")
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("global.changePassword")}</>
          ) : (
            <>{t("hrProfile.backToProfile")}</>
          )}
        </Button>
      </div>
      {/* change password modal */}
      <Modal footer={null} open={changePasswordModal}
        onCancel={() => dispatch(setChangePasswordModal(false))}>
        <ChangePassword state={user} />
      </Modal>
    </>
  );
}
