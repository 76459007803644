import React from "react";
import VisaNumberAndImage from "./VisaNumberAndImage";
import VisaIssueAndExpiry from "./VisaIssueAndExpiry";

export default function VisaDetails() {
  return (
    <>
      {/* VisaNumberAndImage */}
      <VisaNumberAndImage />
      {/* VisaIssueAndExpiry */}
      <VisaIssueAndExpiry />
    </>
  );
}
