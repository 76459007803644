import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

export default function InsuranceIssueAndExpiry() {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          value={user?.insuranceIssue}
          size="small"
          type="text"
          label={t("employees.insuranceIssue")}
          placeholder="e.g.  18/02/2022"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          value={user?.insuranceExpiry}
          size="small"
          label={t("employees.insuranceExpiry")}
          placeholder="e.g. 18/02/2024"
          sx={{ margin: "0.5rem" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  );
}
