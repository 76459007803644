import React, { useEffect } from "react";
import i18n from "../../../assets/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../redux/reduxSlice/globalSlice";
import { Table } from "antd";
import Loading from "../../../components/global/loading/Loading";
import TopButtons from "../../../components/global/employees/employeesComponents/TopButtons";
import TableButtons from "../../../components/global/employees/employeesComponents/TableButtons";

export default function Employees() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width, search, loading } = useSelector((state) => state.global);
  const { employees } = useSelector((state) => state.employees);
  const filteredEmployees = employees?.filter((user) => {
    const searchFieldLower = search.toLowerCase();
    return (
      (user.englishName &&
        user.englishName.toLowerCase().includes(searchFieldLower)) ||
      (user.role && user.role.toLowerCase().includes(searchFieldLower)) ||
      (user.email && user.email.toLowerCase().includes(searchFieldLower)) ||
      (user.phoneNumber &&
        user.phoneNumber.toLowerCase().includes(searchFieldLower))
    );
  });
  useEffect(() => {
    dispatch(setSearch("")); // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: t("employees.tableColOneTitle"),
      dataIndex: t("employees.tableKeyOne"),
      sorter: (a, b) => a.englishName.localeCompare(b.englishName),
    },
    {
      title: t("employees.tableColTwoTitle"),
      dataIndex: t("employees.tableKeyTwo"),
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: t("employees.tableColThreeTitle"),
      dataIndex: t("employees.tableKeyThree"),
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("employees.tableColFourTitle"),
      dataIndex: t("employees.tableKeyFour"),
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: t("employees.tableColFiveTitle"),
      dataIndex: t("employees.tableKeyFive"),
      render: (_, data) => (
        <>
          <TableButtons data={data} />
        </>
      ),
    },
  ];

  if (loading) return <Loading />;

  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <TopButtons />
        {/* table */}
        <div className="global-main">
          <Table
            pagination={{
              position: [i18n.language === "en" ? "bottomRight" : "bottomLeft"],
            }}
            scroll={{
              x: 1500,
            }}
            bordered
            columns={columns}
            dataSource={filteredEmployees}
            style={{ direction: i18n.language === "en" ? "" : "rtl" }}
          />
        </div>
      </div>
    </>
  );
}
