import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../translationFiles/english.json"; // English translation file
import arTranslation from "../translationFiles/arabic.json"; // Arabic translation file

// Initialize i18next
i18n
  .use(initReactI18next) // Bind react-i18next to i18next
  .init({
    resources: {
      en: {
        translation: enTranslation, // English translation resource
      },
      ar: {
        translation: arTranslation, // Arabic translation resource
      },
    },
    lng: "en", // Set the default language
    fallbackLng: "en", // Fallback language in case translation is missing
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
