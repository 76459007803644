import React, { useEffect } from "react";
import CarouselComponent from "../../../components/global/carousel/CarouselComponent";
import Footer from "../../../components/global/footer/Footer";
import Header from "../../../components/global/header/Header";
import WhatsAppButton from "../../../components/global/whatsappButton/WhatsappButton";
import AboutUs from "../../../components/global/aboutUs/AboutUs";
import { scrollToTop } from "../../../utils/scrollToTop";

export default function About() {
  useEffect(() => {
    scrollToTop();   // eslint-disable-next-line
  }, [])
  return (
    <>
      <Header />
      <CarouselComponent />
      <AboutUs />
      <Footer />
      <WhatsAppButton />
    </>
  );
}
