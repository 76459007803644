import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";
import { TextField } from "@mui/material";

export default function TransportAndTotal() {
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  const { t } = useTranslation();
  return (
    <>
      <div className="global-main">
        <TextField
          fullWidth
          id="transport"
          name="transport"
          autoComplete="transport"
          autoFocus
          error={!!editEmployeeErrors?.transport}
          helperText={editEmployeeErrors?.transport}
          value={editEmployeeForm?.transport}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="number"
          label={t("employees.transport")}
          placeholder="e.g. 1000 aed"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          fullWidth
          id="total"
          name="total"
          autoComplete="total"
          autoFocus
          error={!!editEmployeeErrors?.total}
          helperText={editEmployeeErrors?.total}
          value={editEmployeeForm?.total}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees
            )
          }

          size="small"
          type="number"
          label={t("employees.total")}
          placeholder="e.g.  4000 aed"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
