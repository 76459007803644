import { Button } from "@mui/material";
import React from "react";
import i18n from "../../../../assets/i18n/i18n";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function TopButtons() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <div className="global-main">
        <Button
          onClick={
            i18n.language === "en"
              ? user?.role === "hr" ? () => navigate("/eitmamland/hr/employees") : () => navigate("/eitmamland/supervisor/employees")
              : user?.role === "hr" ? () => navigate("/eitmamland/hr") : () => navigate("/eitmamland/supervisor")
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("employees.backToEmployees")}</>
          ) : (
            <>{t("global.dashboardButton")}</>
          )}
        </Button>
        <Button
          onClick={
            i18n.language === "en"
              ? user?.role === "hr" ? () => navigate("/eitmamland/hr") : () => navigate("/eitmamland/supervisor")
              : user?.role === "hr" ? () => navigate("/eitmamland/hr/employees") : () => navigate("/eitmamland/supervisor/employees")
          }
          sx={{
            margin: "0.5rem 0rem",
            background: "#d4df98",
            "&:hover": {
              background: "#a4b964",
            },
          }}
          variant="contained"
        >
          {i18n.language === "en" ? (
            <>{t("global.dashboardButton")}</>
          ) : (
            <>{t("employees.backToEmployees")}</>
          )}
        </Button>
      </div >
    </>
  );
}
