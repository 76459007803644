import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistCombineReducers,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import userSlice from "../reduxSlice/userSlice";
import loginSlice from "../reduxSlice/loginSlice";
import globalSlice from "../reduxSlice/globalSlice";
import employeesSlice from "../reduxSlice/employeesSlice";
import leavesSlice from "../reduxSlice/leavesSlice";
import requestsSlice from "../reduxSlice/requestsSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["user"],
};

const rootReducer = {
  user: userSlice,
  login: loginSlice,
  global: globalSlice,
  employees: employeesSlice,
  leaves: leavesSlice,
  requests: requestsSlice
};

const _persistedReducer = persistCombineReducers(persistConfig, rootReducer);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistedStore = persistStore(store);
