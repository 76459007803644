import React from "react";
import BasicAndResidenceAllowance from "./BasicAndResidenceAllowance";
import TransportAndTotal from "./TransportAndTotal";

export default function SalaryDetails() {
  return (
    <>
      {/* BasicAndResidenceAllowance */}
      <BasicAndResidenceAllowance />
      {/* TransportAndTotal */}
      <TransportAndTotal />
    </>
  );
}
