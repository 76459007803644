import { ref } from "firebase/storage";
import { storage } from "../firebase/firebase";

export const findImagePath = (file) => {
    let pictureItem = file;
    let url_token = pictureItem.split("?");
    let Url = url_token[0].split("/");
    let filePath = Url[Url.length - 1].replaceAll("%2F", "/");
    const imageRef = ref(storage, `${filePath}`);
    return imageRef
}