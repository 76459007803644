import React from 'react'
import { useTranslation } from 'react-i18next'
import "../../../assets/styles/Services.css"
import federalAuthority from "../../../assets/images/federalAuthority.webp"
import notary from "../../../assets/images/notary.webp"
import mohre from "../../../assets/images/mohre.jfif"
import business from "../../../assets/images/business.jfif"
import tadbeer from "../../../assets/images/tadbeer.png"
import abuDhabi from "../../../assets/images/abuDhabi.jpg"
import tamm from "../../../assets/images/tamm.jpg"
import shamTravels from "../../../assets/images/shamTravels.png"
import civilDefense from "../../../assets/images/civilDefense.jpg"
import media from "../../../assets/images/media.png"
import ports from "../../../assets/images/ports.png"
import fish from "../../../assets/images/fish.jpg"
import tasheel from "../../../assets/images/tasheel.jfif"

export default function OurServices() {
    const { t } = useTranslation()
    return (
        <>
            <div className="container">
                <h1>{t("header.services")}</h1>
                <p>{t("services.tagline")}</p>
                <div className="services">
                    <div className="card">
                        <img src={mohre} alt="Tas-heel Services" />
                        <h3>{t("services.tasheel.title")}</h3>
                        <p>{t("services.tasheel.description")}</p>
                    </div>
                    <div className="card">
                        <img src={notary} alt="Notary Services" />
                        <h3>{t("services.notary.title")}</h3>
                        <p>{t("services.notary.description")}</p>
                    </div>
                    <div className="card">
                        <img src={business} alt="Business Start-Up Services" />
                        <h3>{t("services.business.title")}</h3>
                        <p>{t("services.business.description")}</p>
                    </div>
                    <div className="card">
                        <img src={tadbeer} alt="Domestic Workers Services" />
                        <h3>{t("services.domesticWorkers.title")}</h3>
                        <p>{t("services.domesticWorkers.description")}</p>
                    </div>
                    <div className="card">
                        <img src={federalAuthority} alt="Identity and Citizenship Services" />
                        <h3>{t("services.identityCitizenship.title")}</h3>
                        <p>{t("services.identityCitizenship.description")}</p>
                    </div>
                    <div className="card">
                        <img src={abuDhabi} alt="Government Services" />
                        <h3>{t("services.government.title")}</h3>
                        <p>{t("services.government.description")}</p>
                    </div>
                    <div className="card">
                        <img src={tasheel} alt="Public Relations Services" />
                        <h3>{t("services.publicRelations.title")}</h3>
                        <p>{t("services.publicRelations.description")}</p>
                    </div>
                    <div className="card">
                        <img src={tamm} alt="Legal Translation Services" />
                        <h3>{t("services.legalTranslation.title")}</h3>
                        <p>{t("services.legalTranslation.description")}</p>
                    </div>
                    <div className="card">
                        <img src={shamTravels} alt="Travel and Tourism Services" />
                        <h3>{t("services.travelTourism.title")}</h3>
                        <p>{t("services.travelTourism.description")}</p>
                    </div>
                    <div className="card">
                        <img src={civilDefense} alt="Civil Defense Services" />
                        <h3>{t("services.civilDefense.title")}</h3>
                        <p>{t("services.civilDefense.description")}</p>
                    </div>
                    <div className="card">
                        <img src={media} alt="Media Council Services" />
                        <h3>{t("services.mediaCouncil.title")}</h3>
                        <p>{t("services.mediaCouncil.description")}</p>
                    </div>
                    <div className="card">
                        <img src={fish} alt="Fishing Licenses" />
                        <h3>{t("services.fishingLicenses.title")}</h3>
                        <p>{t("services.fishingLicenses.description")}</p>
                    </div>
                    <div className="card">
                        <img src={ports} alt="Security Permits" />
                        <h3>{t("services.securityPermits.title")}</h3>
                        <p>{t("services.securityPermits.description")}</p>
                    </div>
                </div>
                <p>{t("services.closing")}</p>
            </div>
        </>
    )
}
