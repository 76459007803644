import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    formData: {},
    errors: {},
    loading: false,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearLoginForm: (state) => {
      state.formData = {};
      state.errors = {};
      state.loading = false;
    },
  },
});

export const {
  setFormData,
  setErrors,
  clearLoginForm,
  setLoading,
} = loginSlice.actions;

export default loginSlice.reducer;
