import React from "react";
import ResidencyNumberAndImage from "./ResidencyNumberAndImage";
import ResidencyIssueAndExpiry from "./ResidencyIssueAndExpiry";

export default function ResidenceDetails() {
  return (
    <>
      {/* ResidencyNumberAndImage */}
      <ResidencyNumberAndImage />
      {/* ResidencyIssueAndExpiry */}
      <ResidencyIssueAndExpiry />
    </>
  );
}
