import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleEditEmployeeInputChange } from "../../../../../libs/employees/editEmployee/editEmployeeFunctions";


export default function EnglishNameAndEmail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { editEmployeeForm, editEmployeeErrors, employees } = useSelector(
    (state) => state.employees
  );
  return (
    <>
      <div className="global-main">
        <TextField
          required
          fullWidth
          id="englishName"
          name="englishName"
          autoComplete="englishName"
          autoFocus
          error={!!editEmployeeErrors?.englishName}
          helperText={editEmployeeErrors?.englishName}
          value={editEmployeeForm?.englishName}
          onChange={(e) =>
            handleEditEmployeeInputChange(
              e,
              editEmployeeForm,
              dispatch, t, employees

            )
          }
          size="small"
          type="text"
          label={t("employees.englishName")}
          placeholder="e.g. John Doe"
          sx={{ margin: "0.5rem" }}
        />
        <TextField
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          value={editEmployeeForm?.email}
          disabled={true}
          type="email"
          size="small"
          label={t("employees.email")}
          placeholder="e.g. email@eitmamtasheel.ae"
          sx={{ margin: "0.5rem" }}
        />
      </div>
    </>
  );
}
