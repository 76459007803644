import React, { useEffect, useState } from "react";
import { MdMail, MdCall } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp, FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "../../../assets/styles/Header.css"; // Import CSS for RTL styles
import i18n from "../../../assets/i18n/i18n"; // Import the i18n instance
import { handleLanguageChange } from "../../../utils/changeLanguage";
import DrawerNav from "./DrawerNav";

const size = 20;

export default function Header() {
  const { t } = useTranslation(); // Access translations
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScroll(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <>
      <div className="header-top1">
        <div className="contact-info1">
          <div>
            <MdMail size={size} />
            <span><a
              style={{ color: "white" }}
              href="mailto:info@eitmamtasheel.ae"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@eitmamtasheel.ae
            </a></span>
          </div>
          <div>
            <MdCall size={size} style={{ marginLeft: "1rem" }} />
            <span>+971-2-5833982</span>
          </div>
        </div>
        <div className="social-icons">
          <FaFacebookF size={size} style={{ margin: "0rem 0.5rem" }} />
          <a href="https://wa.me/+97125833982" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={size} style={{ color: "white", margin: "0rem 0.5rem" }} />
          </a>
          <a href="https://www.linkedin.com/in/eitmam-land-92a588319/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn size={size} style={{ color: "white", margin: "0rem 0.5rem" }} />
          </a>
        </div>
      </div>
      {i18n.language === "en" ? (
        <>
          {/* english */}
          <div
            className={`header-bottom ${scroll > 30 ? "header-bottom-fix" : ""
              }`}
          >
            <div className="logo" onClick={() => navigate("/")}>
              {t("header.name")}
            </div>
            <div className="drawer-nav" onClick={handleDrawer}>
              <DrawerNav />
            </div>
            <div className="menu">
              <span className="menu-item" onClick={() => { navigate("/") }}>
                {t("header.home")}
              </span>
              <span className="menu-item" onClick={() => navigate("/about")}>
                {t("header.about")}
              </span>
              <span className="menu-item" onClick={() => navigate("/services")}>
                {t("header.services")}
              </span>
              <span className="menu-item" onClick={() => navigate("/contact")}>
                {t("header.contact")}
              </span>
              <span className="menu-item" onClick={handleLanguageChange}>
                {t("header.language")}
              </span>
            </div>
            <div className="language-selector">
              <span className="menu-item" onClick={() => navigate("/login")}>
                {t("header.login")}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* arabic */}
          <div
            className={`header-bottom ${scroll > 30 ? "header-bottom-fix" : ""
              }`}
          >
            <div className="language-selector">
              <span className="menu-item" onClick={() => navigate("/login")}>
                {t("header.login")}
              </span>
            </div>
            <div className="menu">
              <span className="menu-item" onClick={handleLanguageChange}>
                {t("header.language")}
              </span>
              <span className="menu-item" onClick={() => navigate("/contact")}>
                {t("header.contact")}
              </span>
              <span className="menu-item" onClick={() => navigate("/services")}>
                {t("header.services")}
              </span>
              <span className="menu-item" onClick={() => navigate("/about")}>
                {t("header.about")}
              </span>
              <span className="menu-item" onClick={() => navigate("/")}>
                {t("header.home")}
              </span>
            </div>
            <div className="drawer-nav" onClick={handleDrawer}>
              <DrawerNav />
            </div>
            <div className="logo" onClick={() => navigate("/")}>
              {t("header.name")}
            </div>
          </div>
        </>
      )}
    </>
  );
}
