import { deleteObject, ref } from "firebase/storage";
import { message } from "antd";
import { storage } from "../firebase/firebase";


export const deleteFileByURL = async (storageURL) => {
    try {
        var pictureItem = storageURL;
        var url_token = pictureItem.split("?");
        var Url = url_token[0].split("/");
        var filePath = Url[Url.length - 1].replaceAll("%2F", "/");
        const fileRef = ref(storage, filePath);
        // Delete the file
        await deleteObject(fileRef);
    } catch (error) {
        message.error(error.message);
    }
};