
import { useTranslation } from 'react-i18next';
import "../../../assets/styles/PrivacyPolicy.css"
import React, { useEffect } from "react";
import CarouselComponent from "../../../components/global/carousel/CarouselComponent";
import Footer from "../../../components/global/footer/Footer";
import Header from "../../../components/global/header/Header";
import WhatsAppButton from "../../../components/global/whatsappButton/WhatsappButton";
import i18n from '../../../assets/i18n/i18n';
import { scrollToTop } from "../../../utils/scrollToTop";

const TermsOfUse = () => {
    const { t } = useTranslation(); // Initialize the useTranslation hook
    useEffect(() => {
        scrollToTop();   // eslint-disable-next-line
    }, [])
    return (
        <>
            <Header />
            <CarouselComponent />
            <div className="container">
                <h1 style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>{t('termsOfUse.title')}</h1>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.introduction.title')}</h2>
                    <p>{t('termsOfUse.sections.introduction.content')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.userResponsibilities.title')}</h2>
                    <p>{t('termsOfUse.sections.userResponsibilities.content')}</p>
                    <p>{t('termsOfUse.sections.userResponsibilities.content1')}</p>
                    <p>{t('termsOfUse.sections.userResponsibilities.content2')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.contentUsage.title')}</h2>
                    <p>{t('termsOfUse.sections.contentUsage.content')}</p>
                    <p>{t('termsOfUse.sections.contentUsage.content1')}</p>
                    <p>{t('termsOfUse.sections.contentUsage.content2')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.privacy.title')}</h2>
                    <p>{t('termsOfUse.sections.privacy.content')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.termination.title')}</h2>
                    <p>{t('termsOfUse.sections.termination.content')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.disputeResolution.title')}</h2>
                    <p>{t('termsOfUse.sections.disputeResolution.content')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.limitationOfLiability.title')}</h2>
                    <p>{t('termsOfUse.sections.limitationOfLiability.content')}</p>
                    <p>{t('termsOfUse.sections.limitationOfLiability.content1')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.changesToTheTerms.title')}</h2>
                    <p>{t('termsOfUse.sections.changesToTheTerms.content')}</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.contact.title')}</h2>
                    <p>{t('termsOfUse.sections.contact.content')} <a href="mailto:info@eitmamtasheel.ae">info@eitmamtasheel.ae</a>.</p>
                </section>

                <section style={{
                    textAlign: i18n.language === "en" ? "left" : "right",
                }}>
                    <h2>{t('termsOfUse.sections.miscellaneous.title')}</h2>
                    <p>{t('termsOfUse.sections.miscellaneous.content')}</p>
                </section>
            </div>
            <Footer />
            <WhatsAppButton />
        </>
    );
};

export default TermsOfUse;
