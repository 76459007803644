import React, { useState } from "react";
import i18n from "../../../assets/i18n/i18n";
import { MdMenu } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleLanguageChange } from "../../../utils/changeLanguage";
import { FaHome, FaUser, FaUsers, FaLanguage, } from "react-icons/fa";
import { MdSick, MdFlight, } from "react-icons/md";
import { useSelector } from "react-redux";
import { IoIosLogIn } from "react-icons/io";
import { FaCodePullRequest } from "react-icons/fa6";

export default function DrawerSidebar({ handleLogout }) {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  const drawerSidebar = [
    {
      id: 1,
      title:
        user?.role === "user"
          ? t("sidebar.dashboard")
          : t("sidebarHr.dashboard"),
      icon: <FaHome />,
      onClick:
        user?.role === "user"
          ? () => navigate("/eitmamland/user")
          : user?.role === "hr" ? () => navigate("/eitmamland/hr") : () => navigate("/eitmamland/supervisor"),

    },
    {
      id: 2,
      title:
        user?.role === "user"
          ? t("sidebar.sickLeave")
          : t("sidebarHr.employees"),
      icon: user?.role === "user" ? <MdSick /> : <FaUsers />,
      onClick:
        user?.role === "user"
          ? () => navigate("/eitmamland/user/sickLeave")
          : user?.role === "hr" ? () => navigate("/eitmamland/hr/employees") : () => navigate("/eitmamland/supervisor/employees"),

    },
    {
      id: 3,
      title:
        user?.role === "user"
          ? t("sidebar.annualLeave")
          : t("sidebarHr.sickLeave"),
      icon: user?.role === "user" ? <MdFlight /> : <MdSick />,
      onClick:
        user?.role === "user"
          ? () => navigate("/eitmamland/user/annualLeave")
          : user?.role === "hr" ? () => navigate("/eitmamland/hr/sickLeave") : () => navigate("/eitmamland/supervisor/sickLeave"),

    },
    {
      id: 4,
      title: user?.role === "user" ? t("sidebar.request") : t("sidebarHr.annualLeave"),
      icon: user?.role === "user" ? <FaCodePullRequest /> : <MdFlight />,
      onClick:
        user?.role === "user"
          ? () => navigate("/eitmamland/user/requests")
          : user?.role === "hr" ? () => navigate("/eitmamland/hr/annualLeave") : () => navigate("/eitmamland/supervisor/annualLeave"),

    },
    {
      id: 5,
      title: user?.role === "user" ? t("sidebar.profile") : t("sidebarHr.request"),
      icon: user?.role === "user" ? <FaUser /> : <FaCodePullRequest />,
      onClick:
        user?.role === "user"
          ? () => navigate("/eitmamland/user/profile")
          : user?.role === "hr" ? () => navigate("/eitmamland/hr/requests") : () => navigate("/eitmamland/supervisor/requests"),

    },
    {
      id: 6,
      title:
        user?.role === "user" ? t("header.language") : t("sidebarHr.profile"),
      icon: user?.role === "user" ? <FaLanguage /> : <FaUser />,

      onClick:
        user?.role === "user"
          ? () => handleLanguageChange()
          : user?.role === "hr" ? () => navigate("/eitmamland/hr/profile") : () => navigate("/eitmamland/supervisor/profile"),

    },
    {
      id: 7,
      title: user?.role === "user" ? t("header.logout") : t("header.language"),
      icon: user?.role === "user" ? <IoIosLogIn /> : <FaLanguage />,
      onClick: user?.role === "user" ? () => handleLogout() : () => handleLanguageChange(),
    },
    {
      id: 8,
      title: user?.role === "user" ? "" : t("header.logout"),
      icon: user?.role === "user" ? "" : <IoIosLogIn />,
      onClick: user?.role === "user" ? "" : () => handleLogout(),
    },
  ];

  return (
    <>
      {i18n.language === "en" ? (
        <>
          <div className="drawer">
            <MdMenu
              size={25}
              style={{ display: drawer ? "none" : "flex" }}
              onClick={handleDrawer}
            />
            <div className={`drawer-inner ${drawer ? "open" : ""}`}>
              <div className="drawer-close">
                <IoClose size={35} onClick={handleDrawer} />
              </div>
              <div className="drawer-content">
                {drawerSidebar?.map((item) => {
                  return (
                    <>
                      <span
                        key={item?.id}
                        className="drawer-content-item"
                        onClick={item?.onClick}
                      >
                        {i18n.language === "en" ? (
                          <>
                            <span>{item?.icon}</span>
                            <span>{item?.title}</span>
                          </>
                        ) : (
                          <>
                            <span>{item?.title}</span>
                            <span>{item?.icon}</span>
                          </>
                        )}
                      </span>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="drawer">
            <MdMenu
              size={25}
              style={{ display: drawer ? "none" : "flex" }}
              onClick={handleDrawer}
            />
            <div className={`drawer-inner1 ${drawer ? "open" : ""}`}>
              <div className="drawer-close1">
                <IoClose size={35} onClick={handleDrawer} />
              </div>
              <div className="drawer-content">
                {drawerSidebar?.map((item) => {
                  return (
                    <>
                      <span
                        key={item?.id}
                        className="drawer-content-item"
                        style={{
                          justifyContent: "flex-end",
                        }}
                        onClick={item?.onClick}
                      >
                        {i18n.language === "en" ? (
                          <>
                            <span>{item?.icon}</span>
                            <span>{item?.title}</span>
                          </>
                        ) : (
                          <>
                            <span>{item?.title}</span>
                            <span>{item?.icon}</span>
                          </>
                        )}
                      </span>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
