import React from "react";
import image3 from "../../../assets/images/image3.png";
import logo from "../../../assets/images/logo.png";
import "../../../assets/styles/Login.css";
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Copyright } from "../../../utils/copyright";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import i18n from "../../../assets/i18n/i18n";
import { handleLanguageChange } from "../../../utils/changeLanguage";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setShowPassword } from "../../../redux/reduxSlice/globalSlice";
import Loading from '../../../components/global/loading/Loading';
import {
  handleInputChange,
  handleSubmit,
} from "../../../libs/login/loginFunctions";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formData, errors, loading } = useSelector(
    (state) => state.login
  );
  const { showPassword } = useSelector(
    (state) => state.global
  );
  if (loading) return <Loading />;
  return (
    <>
      <div
        className="main"
        style={{
          backgroundImage: `url(${image3})`,
        }}
      >
        <div className="main-inner">
          <div className="image-container-outer">
            <div className="image-container">
              <img src={logo} alt="Eitmam Land" className="image" />
            </div>
          </div>
          <div className="text-container-outer">
            <div className="text-container">
              <div
                style={{ width: "90%", cursor: "pointer" }}
                onClick={handleLanguageChange}
              >
                {i18n.language === "en" ? (
                  <>{t("login.language")}</>
                ) : (
                  <>{t("login.language")}</>
                )}
              </div>
              <h1>
                {i18n.language === "en" ? (
                  <>{t("login.title")}</>
                ) : (
                  <>{t("login.title")}</>
                )}
              </h1>

              <div className="text-inner">
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={!!errors?.email}
                  helperText={errors?.email}
                  value={formData?.email}
                  onChange={(e) => handleInputChange(e, formData, dispatch)}
                  size="small"
                  type="email"
                  label={
                    i18n.language === "en" ? (
                      <>{t("login.email")}</>
                    ) : (
                      <>{t("login.email")}</>
                    )
                  }
                  placeholder="e.g. email@eitmamtasheel.ae"
                  sx={{ margin: "0.5rem 0rem" }}
                />
                <TextField
                  size="small"
                  label={
                    i18n.language === "en" ? (
                      <>{t("login.password")}</>
                    ) : (
                      <>{t("login.password")}</>
                    )
                  }
                  placeholder="*******"
                  sx={{ margin: "0.5rem 0rem" }}
                  required
                  fullWidth
                  name="password"
                  value={formData?.password}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  error={!!errors?.password}
                  helperText={errors?.password}
                  onChange={(e) => handleInputChange(e, formData, dispatch)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            dispatch(setShowPassword(!showPassword))
                          }
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  onClick={(e) =>
                    handleSubmit(e, formData, errors, navigate, dispatch, t)
                  }
                  fullWidth
                  sx={{
                    margin: "0.5rem 0rem",
                    background: "#d4df98",
                    "&:hover": {
                      background: "#a4b964",
                    },
                  }}
                  variant="contained"
                >
                  {i18n.language === "en" ? (
                    <>{t("login.title")}</>
                  ) : (
                    <>{t("login.title")}</>
                  )}
                </Button>
              </div>
              <div style={{ marginTop: "15%" }}>
                <Copyright sx={{ mt: 1, mb: 1 }} t={t} color="black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
