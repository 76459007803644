import React, { useEffect } from "react";
import i18n from "../../../assets/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../redux/reduxSlice/globalSlice";
import { Tabs } from "antd";
import BasicDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/basicDetails/BasicDetails";
import ProfileDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/profileDetails/ProfileDetails";
import EmiratesIdDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/emiratesIdDetails/EmiratesIdDetails";
import SalaryDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/salaryDetails/SalaryDetails";
import PassportDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/passportDetails/PassportDetails";
import VisaDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/visaDetails/VisaDetails";
import InsuranceDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/insuranceDetails/InsuranceDetails";
import ResidenceDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/residenceDetails/ResidenceDetails";
import WorkPermitDetails from "../../../components/supervisor/supervisorProfile/viewSupervisorProfile/workPermitDetails/WorkPermitDetails";
import TopButtons from "../../../components/supervisor/supervisorProfile/supervisorProfileComponents/TopButtons";

export default function SupervisorProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useSelector((state) => state.global);
  useEffect(() => {
    dispatch(setSearch("")); // eslint-disable-next-line
  }, []);
  const items = [
    {
      key: "1",
      label: t("employees.basicDetails"),
      children: <BasicDetails />,
    },
    {
      key: "2",
      label: t("employees.profileDetails"),
      children: <ProfileDetails />,
    },
    {
      key: "3",
      label: t("employees.emiratesIdDetails"),
      children: <EmiratesIdDetails />,
    },
    {
      key: "4",
      label: t("employees.salaryDetails"),
      children: <SalaryDetails />,
    },
    {
      key: "5",
      label: t("employees.passportDetails"),
      children: <PassportDetails />,
    },
    {
      key: "6",
      label: t("employees.visaDetails"),
      children: <VisaDetails />,
    },
    {
      key: "7",
      label: t("employees.insuranceDetails"),
      children: <InsuranceDetails />,
    },
    {
      key: "8",
      label: t("employees.residenceDetails"),
      children: <ResidenceDetails />,
    },
    {
      key: "9",
      label: t("employees.workPermitDetails"),
      children: <WorkPermitDetails />,
    },
  ];
  return (
    <>
      <div
        className="global-class global-main-outer"
        style={{
          paddingLeft: i18n.language === "en" && width > 900 ? "13%" : 0,
          paddingRight: i18n.language !== "en" && width > 900 ? "13%" : 0,
        }}
      >
        {/* TopButtons */}
        <TopButtons />
        {/* Tabs */}
        <div className="global-main">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            style={{ width: "100%" }}
            items={items}
          />
        </div>
      </div>
    </>
  );
}
